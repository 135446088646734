<template>
  <div class="stepsdemo-content" id="step-info-contratto">
    <Card>
      <template v-slot:title v-if="visibleStepInfo">
        <div class="grid"> 
          <p class="col-12 text-center">Info Contratto</p>
        </div>
      </template>
      <template v-slot:subtitle v-if="visibleStepInfo">
        <p>Inserisci le info del contratto</p>
        <div class="card bg-warning" style="font-weight: bold;">
          <p class="mb-2">
            Per l'inserimento di un contratto assicurarsi di avere i dati del cliente e/o rappresentante, documento d'identità del firmatario, ultima fattura di ogni punto di fornitura, offerte attive.
          </p>
          <p>
            Per contratti firmati manualmente sono necessari anche il pdf del contratto firmato, ed eventuali moduli
          </p>
        </div>
      </template> 
      <template v-slot:content>
        <div style="position: relative;">
          <Button class="btn-offerte btn-tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>
          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false" style="min-width: 300px;">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div :class="['h-full',{'flex align-items-center': windowSize < 700}]">
                  <div v-if="windowSize > 700" class="border-1 surface-border border-round text-center py-5 px-3">
                    <div class="mb-2">
                      <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+(windowSize < 1400 ? slotProps.data.size+3 : slotProps.data.size)]"/>
                    </div>
                  </div>
                  
                  <p class="m-2 text-center"><strong>{{ slotProps.data.description }}</strong></p>

                </div>
              </template>
            </Carousel>
          </Dialog>
        </div>
        <div style="margin-top: 50px;">
          <div class="grid justify-content-center" v-if="!visibleStepInfo">
            <Button class="btn-big" label="Nuovo Contratto" @click="setNewContract()"/>
            <Button class="btn-big" label="Nuovo Contratto con Volture" @click="setNewContract('v')"/>
            <Button class="btn-big" label="Nuovo Contratto con Subentri" @click="setNewContract('s')"/>
          </div>
  
          <div class="grid m-2" v-if="visibleStepInfo">
            <div v-if="currentUser.role == 'admin'" class="col-12">
              <Dropdown v-model="selectedAgent" :options="agents" filter optionLabel="name" optionValue="id" placeholder="Seleziona un agente" class="w-full" @update:modelValue="onChangeAgent()"/>
              <div v-show="validationErrors.selectedAgent && submitted" class="p-error">Seleziona un agente.</div>
            </div>
            <div v-else class="col-12">
              <TreeSelect v-model="selectedKeyAgent" :options="agents" filter optionLabel="name" optionValue="id" placeholder="Seleziona un agente" class="w-full" @node-select="onChangeAgent($event)" />
              <div v-show="validationErrors.selectedAgent && submitted" class="p-error">Seleziona un agente.</div>
            </div>
            <!-- Selezione IVA agevolata -->
            <div class="col-12 xl:col-2 text-center">
              <label class="m-3"><strong>Iva Agevolata Energia</strong></label>
              <div class="m-3">
                <Checkbox v-model="isIvaAgevolata" :binary="true"/>
              </div>
            </div>

            <!-- Selezione type spedizione -->
            <div class="col-12 xl:col-2 text-center">
              <label class="m-3"><strong>Tipologia di spedizione</strong></label>
              <div v-for="spedizione in spedizioni" :key="spedizione.key" class="m-auto col-12 flex p-0">
                <div class="col-3 sm:col-4 lg:col-1 p-0 m-0"></div>
                <div class="col-6 sm:col-4 lg:col-10 m-0 flex justify-content-start">
                  <RadioButton v-model="selectedSpedizione" :inputId="spedizione.key" :value="spedizione.name" />
                  <label :for="spedizione.key" class="ml-2">{{ spedizione.name }}</label>
                </div>
                <div class="col-3 sm:col-4 lg:col-1 p-0 m-0"></div>
              </div>
              <div v-show="validationErrors.spedizione && submitted" class="p-error">Campo richiesto.</div>
            </div>

            <!-- Selezione frequenza -->
            <!-- <div class="col-12 xl:col-2 text-center">
              <label class="m-3"><strong>Frequenza di fatturazione</strong></label>
              <div v-for="frequenza in frequenze" :key="frequenza.key" class="m-auto col-12 flex p-0">
                <div class="col-3 sm:col-4 lg:col-1 p-0 m-0"></div>
                <div class="col-6 sm:col-4 lg:col-10 m-0 flex justify-content-start">
                  <RadioButton v-model="selectedFrequenza" :inputId="frequenza.key" :value="frequenza.name" />
                  <label :for="frequenza.key" class="ml-2">{{ frequenza.name }}</label>
                </div>
                <div class="col-3 sm:col-4 lg:col-1 p-0 m-0"></div>
              </div>
              <div v-show="validationErrors.frequenza && submitted" class="p-error">Campo richiesto.</div>
            </div> -->

            <!-- Selezione data switch -->
            <div class="col-12 xl:col-2 text-center">
              <label class="m-3"><strong>Data switch</strong></label>
              <div class="m-3">
                <Checkbox id="isCheckedDataSwitch" v-model="isCheckedDataSwitch" :binary="true"/>
                <label for="isCheckedDataSwitch" class="m-3">Prima data utile</label>
              </div>

              <div v-show="!isCheckedDataSwitch">
                <div class="mb-3">
                  <label for="pod" class="m-3">Pod</label>
                  <Calendar v-model="dataSwitchPod" :minDate="minDatePod" :maxDate="maxDatePod" view="month" dateFormat="mm/yy" style="width:6rem" :manualInput="false"/>
                </div>
                <div>
                  <label for="pod" class="m-3">Pdr </label>
                  <Calendar v-model="dataSwitchPdr" :minDate="minDatePdr" :maxDate="maxDatePdr" view="month" dateFormat="mm/yy" style="width:6rem" :manualInput="false"/>
                </div>
              </div>
            </div>
            
            <div class="col-12 xl:col-2 text-center" v-if="canViewInputPromo">
              <label class="m-3"><strong>Codice promozionale</strong></label>
              <div class="flex flex-wrap align-items-center justify-content-center">
                <InputText v-model="codice_promozionale" type="text" class="m-3" v-on:keydown.enter="verificaCodicePromozionale()" @input="checkCanGoToNext($event)" @blur="verificaCodicePromozionale(false)"/>
                <Button v-show="canViewButtonCheckCodicePromozionale" icon="pi pi-check" v-tooltip="`Verifica codice promozionale`" @click="verificaCodicePromozionale()"/>
              </div>
              <div class="flex flex-column justify-content-center col-4 align-items-center">
              </div>
            </div>
            <div v-else class="col-12 xl:col-2 text-center">
            </div>


            <!-- Selezione contratto manuale -->
            <div class="col-12 xl:col-4 text-center">
              <label class="m-3"><strong>Contratto firmato manualmente</strong></label>
              <div class="m-3">
                <Checkbox v-model="isManualContract" :binary="true"/>
              </div>
            </div>

            <!-- View offerte -->
            <div class="col-12">
                <Button v-tooltip="`Offerte applicabili`" icon="pi pi-info" class="btn-offerte" @click="openOfferte"/>
                <div v-if="visibleOfferte">
                  <div v-for="offerta in offerte" :key="offerta.id" class="badge-offerta">
                    {{offerta.name}}
                  </div>
                </div>
                <div v-show="validationErrors.offerte && submitted" class="p-error">Non ci sono offerte applicabili. Contatta il backoffice per attivare un'offerta</div>
            </div>
          </div>
          
          <div class="grid m-2" v-if="visibleStepInfo">
            <div class="flex flex-wrap col-12 p-0 m-0" v-if="!selectedTypeClient">
              <div class="col-6 sm:col-6 xl:col-2" v-for="tipo in typesClient" :key="tipo">
                <Button :label="(tipo.name.replaceAll('/',' ')).toUpperCase()" :class="`w-full btn-filter ${tipo.selected ? 'selected' : ''}`" @click="onChangeTypeClient(tipo.id)"/>
              </div>
              <div v-show="validationErrors.type_client && submitted" class="p-error p-1">Campo richiesto.</div>
            </div>
            <div v-else class="col-12 text-center">
              <h5>
                Contratto {{ typeSelected.toUpperCase() }}
              </h5>
            </div>
            <!-- <div class="col-12 xl:col-6">
              <div class="field col-12">
                <label><strong>Tipo Cliente/i</strong></label>
                <Dropdown 
                v-model="selectedTypeClient" 
                :options="typesClient" 
                optionLabel="name" 
                optionValue="id" 
                placeholder="Seleziona tipo cliente"  
                :class="['inputfield w-full',{'p-invalid': validationErrors.selectedTipo && submitted}]" 
                @change="resetStepIndex"/>
                <div v-show="validationErrors.type_client && submitted" class="p-error">Campo richiesto.</div>
              </div>
              <div class="col-12">
              </div>
            </div> -->
            <div class="col-12 xl:col-6">
              <div id="form-cliente" class="col-12" style="display: none;">
                <TabView v-model:activeIndex="activeTabIndex" selectOnFocus @click="onSelectedView">
                  <TabPanel :header="`Cliente ${typeSelected} esistente`">
                    <div class="formgrid grid mt-4">     
                      <div :class="['p-field', {'col-12': selectedClient == null},{'col-11': selectedClient != null}]">
                        <Dropdown v-model="selectedClient" :filterFields="['ragsoc', 'nome','cognome','piva','codfis']" :options="clienti" filter :placeholder="`Seleziona un cliente ${typeSelected}`" @change="onSelectedExistentClient" :class="['inputfield w-full',{'p-invalid': validationErrors.existentClient && submitted}]">
                          <template #option="slotProps">
                            <div v-if="slotProps.option" class="flex align-items-center">
                              {{ selectedTypeClient == 2 ? `${slotProps.option.nome} ${slotProps.option.cognome} - ${slotProps.option.codfis}` : `${slotProps.option.ragsoc} - ${slotProps.option.piva ?? slotProps.option.codfis}` }}
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps.value" class="flex align-items-center">
                              {{ selectedTypeClient == 2 ? `${slotProps.value.nome} ${slotProps.value.cognome}` : `${slotProps.value.ragsoc}` }}
                            </div>
                          </template>
                        </Dropdown>
                        <div v-show="validationErrors.existentClient && submitted" class="p-error">Seleziona un cliente.</div>
                      </div>
                      <div v-if="selectedClient" class="p-field col-1 flex flex-wrap justify-content-center align-items-center">
                        <i @click="removeExistentClient" class="btn-remove-selected p-error pi pi-times" style="font-size: 1.2rem" v-tooltip="'Rimuovi selezionato'"></i>
                      </div>
                      <div class="p-field col-12">
                        <label v-if="selectedTypeClient == 2 && selectedContact">
                          <strong>Indirizzo di spedizione</strong>
                        </label>
                        <Dropdown :disabled="newContatto == true" dropdown-width="100%" v-if="selectedTypeClient == 2 && selectedClient" v-model="selectedContact" :options="contatti" :placeholder="contatti.length == 0 ? `Non ci sono contatti disponibili per questo cliente. Creane uno nuovo` : `Seleziona un contatto`" class="inputfield w-full mt-2" @change="onSelectedContact">
                          <template #empty>
                            Crea un nuovo indirizzo di spedizione
                          </template>
                          <template #option="slotProps">
                            <div v-if="slotProps.option" class="flex align-items-center">
                              {{ `${slotProps.option.toponimo ?? ''} ${slotProps.option.indirizzo ?? ''} ${slotProps.option.civico ?? ''}, ${slotProps.option.cap ?? ''} - ${slotProps.option.name ?? ''} ${slotProps.option.provincia ?? ''} - ${slotProps.option.cell ? 'Cellulare: '+slotProps.option.cell : ''} - ${slotProps.option.tel ? 'Telefono: '+slotProps.option.tel : ' -'} ${slotProps.option.email ?? ''}` }}
                            </div>
                          </template>
                          <template #value="slotProps">
                            <div v-if="slotProps.value" class="flex align-items-center">
                              {{ `${slotProps.value.toponimo ?? ''} ${slotProps.value.indirizzo ?? ''} ${slotProps.value.civico ?? ''}, ${slotProps.value.cap ?? ''} - ${slotProps.value.name ?? ''} ${slotProps.value.provincia ?? ''}` }}
                            </div>
                          </template>
                        </Dropdown>
                        <div v-show="validationErrors.contact && submitted" class="p-error">Seleziona un contatto.</div>
  
                      </div>
                      <div v-if="selectedClient && selectedTypeClient == 2" class="p-field col-12 flex flex-wrap align-items-center">
                        <label><strong>Crea un nuovo indirizzo di spedizione</strong></label>
                        <Checkbox :disabled="contatti.length == 0" v-model="newContatto" :binary="true" @change="setNewContatto" class="m-2"/>
                      </div>
                      <div v-if="newContatto" class="grid mt-3">
                        <div class="p-field col-12">
                          <h5>Indirizzo di spedizione</h5>
                          <hr>
                        </div> 
                        <div class="p-field col-12 xl:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask @change="onSelectedMobile($event)" id="mobile" mask="999-999999?9" v-model="contatto.cellulare" :class="['inputfield w-full',{'p-invalid': validationErrors.cellulare && submitted}]" />
                        <small v-show="validationErrors.cellulare && submitted" class="p-error">Campo richiesto.</small>
                        <small v-show="validationErrors.cellulare_incorrect" class="p-error">Ricontrolla il numero di cellulare inserito.</small>
                      </div> 
                      <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :modal="true">
                        <div class="confirmation-content">          
                          <p>Hai inserito il numero telefonico: {{ contatto.cellulare }}</p> 
                          <p>É corretto?</p>   
                        </div>
                        <template #footer>
                          <Button @click="isCorrect(true)" label="Si" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito m-2" />
                          <Button @click="isCorrect()" label="No" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2" />
                        </template>
                      </Dialog>
                      <div class="p-field col-12 xl:col-6">
                        <label for="email">Email</label><br>
                        <InputText @change="onSelected($event)" id="email" v-model="contatto.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" @input="(val) => (contatto.email = contatto.email.toLowerCase())"/>
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                      </div>
  
  
                        <div class="p-field col-12 xl:col-6">
                          <label>Toponimo</label><br>
                          <Dropdown @change="onSelectedToponimo($event, 'spedizione')" v-model="contatto.toponimo_spedizione" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" :editable="true"  placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo_spedizione && submitted}]" @input="onInputToponimo($event, 'spedizione')"/>
                          <small v-show="validationErrors.toponimo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div> 
  
                        <div class="p-field col-12 xl:col-6">
                          <label for="indirizzo_spedizione">Indirizzo</label><br>
                          <InputText @change="onSelected($event)" id="indirizzo_spedizione" v-model="contatto.indirizzo_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo_spedizione && submitted}]" @input="(val) => (contatto.indirizzo_spedizione = contatto.indirizzo_spedizione.toUpperCase())"/>
                          <small v-show="validationErrors.indirizzo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label for="civico_spedizione">Civico</label><br>
                          <InputText @change="onSelected($event)" id="civico_spedizione" v-model="contatto.civico_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.civico_spedizione && submitted}]" @input="(val) => (contatto.civico_spedizione = contatto.civico_spedizione.toUpperCase())"/>
                          <small v-show="validationErrors.civico_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label for="cap_spedizione">Cap</label><br>
                          <InputMask mask="99999" @change="onSelected($event)" id="cap_spedizione" v-model="contatto.cap_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.cap_spedizione && submitted}]" />
                          <small v-show="validationErrors.cap_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        
                        <div class="p-field col-12 xl:col-6">
                          <label>Provincia</label><br>
                          <Dropdown @change="onSelectedDistrict($event, 'spedizione')" v-model="contatto.provincia_spedizione" :options="districts" optionLabel="name" :filter="true" optionValue="code" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.provincia_spedizione && submitted}]" />
                          <small v-show="validationErrors.provincia_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label>Comune</label><br>
                          <Dropdown @change="onSelectedCity($event, 'spedizione')" v-model="contatto.comune_spedizione" :options="cities_spedizione" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune_spedizione && submitted}]" />
                          <small v-show="validationErrors.comune_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>    
                      </div>                 
  
                    </div>
                  </TabPanel>
                  <TabPanel :header="`Crea Cliente ${typeSelected}`" :disabled="selectedClient != null">
                    <div class="formgrid grid mt-4">
                      <div class="w-full flex flex-wrap">
                        <div class="p-field col-12 xl:col-6">
                          <label for="codfis">Codice Fiscale</label><br>
                          <!-- <InputText @change="onSelected($event)" id="codfis" v-model="cliente.codfis" :class="['inputfield w-full',{'p-invalid': validationErrors.codfis && submitted}]" @input="onInputCodFis"/> -->
                          <InputMask class="text_toupper" :mask="`${maskCF}`" @change="onSelected($event)" id="codfis" v-model="cliente.codfis" :class="['inputfield w-full',{'p-invalid': validationErrors.codfis && submitted}]" @blur="onInputCodFis($event)"/>
  
                          <small v-show="validationErrors.codfis && submitted" class="p-error">Campo richiesto.</small>
                          <small v-show="validationErrors.codfis_invalid && submitted" class="p-error">Campo non valido.</small>
                          <small v-show="validationErrors.codfis_exist && submitted" class="p-error">Cliente esistente.</small>
                          <div class="col-12 flex justify-content-between mt-3" v-if="selectedTypeClient != 2 && selectedTypeClient != 4">
                            <label><strong>La partita Iva coincide con il codice fiscale</strong></label>
                            <Checkbox v-model="sameCfPiva" :binary="true" @change="setSameCfPiva" :disabled="selectedTypeClient == 4"/>
                          </div>
                        </div> 
                        <div class="p-field col-12 xl:col-6" v-if="selectedTypeClient != 2">
                          <label for="piva">Partita Iva</label><br>
                          <!-- <InputText :disabled="sameCfPiva" @change="onSelected($event)" id="piva" v-model="cliente.piva" :class="['inputfield w-full',{'p-invalid': validationErrors.piva && submitted}]" @input="(val) => (cliente.piva = cliente.piva.toUpperCase())"/> -->
                          <InputMask mask="99999999999" :disabled="sameCfPiva" @change="onSelected($event)" id="piva" v-model="cliente.piva" :class="['inputfield w-full',{'p-invalid': validationErrors.piva && submitted}]"/>
  
                          <small v-show="validationErrors.piva && submitted" class="p-error">Campo richiesto.</small>
                          <small v-show="validationErrors.piva_invalid && submitted" class="p-error">Campo non valido.</small>
                          <small v-show="validationErrors.piva_exist && submitted" class="p-error">Cliente esistente.</small>
                        </div> 
                      </div> 
                      <div v-if="selectedTypeClient == 2" class="p-field col-12 xl:col-6">
                        <label for="nome">Nome</label><br>
                        <InputText @change="onSelected($event)" id="nome" v-model="cliente.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="(val) => (cliente.nome = cliente.nome.toUpperCase())"/>
                        <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div v-if="selectedTypeClient == 2" class="p-field col-12 xl:col-6">
                        <label for="cognome">Cognome</label><br>
                        <InputText @change="onSelected($event)" id="cognome" v-model="cliente.cognome" :class="['inputfield w-full',{'p-invalid': validationErrors.cognome && submitted}]" @input="(val) => (cliente.cognome = cliente.cognome.toUpperCase())"/>
                        <small v-show="validationErrors.cognome && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div v-if="selectedTypeClient != 2" class="p-field col-12">
                        <label for="ragsoc">Ragione Sociale</label><br>
                        <InputText @change="onSelected($event)" id="ragsoc" v-model="cliente.ragsoc" :class="['inputfield w-full',{'p-invalid': validationErrors.ragsoc && submitted}]" @input="(val) => (cliente.ragsoc = cliente.ragsoc.toUpperCase())"/>
                        <small v-show="validationErrors.ragsoc && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask @change="onSelectedMobile($event)" id="mobile" mask="999-999999?9" v-model="cliente.cellulare" :class="['inputfield w-full',{'p-invalid': validationErrors.cellulare && submitted}]" />
                        <small v-show="validationErrors.cellulare && submitted" class="p-error">Campo richiesto.</small>
                        <small v-show="validationErrors.cellulare_incorrect" class="p-error">Ricontrolla il numero di cellulare inserito.</small>
                        <small v-show="validationErrors.noTelCell" class="p-error">Inserisci almeno un numero di cellulare o un telefono fisso</small>
  
                      </div> 
                      <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :modal="true">
                        <div class="confirmation-content">          
                          <p>Hai inserito il numero telefonico: {{ cliente.cellulare }}</p> 
                          <p>É corretto?</p>   
                        </div>
                        <template #footer>
                          <Button @click="isCorrect(true)" label="Si" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito m-2" />
                          <Button @click="isCorrect()" label="No" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2" />
                        </template>
                      </Dialog>
                      <div class="p-field col-12 xl:col-6">
                        <label for="telefono">Telefono</label><br>                   
                        <InputMask @change="onSelectedTelefono($event)" id="telefono" mask="999-999999?9" v-model="cliente.telefono" :class="['inputfield w-full',{'p-invalid': validationErrors.telefono && submitted}]" />
                      </div> 
  
                      <div class="p-field col-12 xl:col-6">
                        <label for="email">Email</label><br>
                        <InputText @change="onSelected($event)" id="email" v-model="cliente.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" @input="(val) => (cliente.email = cliente.email.toLowerCase())"/>
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                      </div>
                      <div v-if="selectedTypeClient != 2" class="p-field col-12 xl:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="cliente.pec" :class="['inputfield w-full',{'p-invalid': validationErrors.pec || validationErrors.ispec && submitted}]" @input="(val) => (cliente.pec = cliente.pec.toLowerCase())"/>
                      </div>
  
                      <div class="p-field col-12 xl:col-6">
                        <label>Codice Sdi</label><br>
                        <Dropdown class="w-full"
                        v-model="cliente.sdi"
                        :options="sdi" 
                        placeholder="Seleziona" 
                        :filter="true"
                        :showClear="true"
                        optionLabel="sdi" optionValue="sdi"                    
                        :disabled="selectedTypeClient == 2"
                        @change="onSelectedSdi"
                        @update:modelValue="onSelectedSdi"
                        :editable="selectedTypeClient == 5"
                        />
                        <small v-show="validationErrors.sdi && submitted" class="p-error">Campo richiesto.</small>
                        <small v-show="validationErrors.sdi_invalid && submitted" class="p-error">Formato errato.</small>
                      </div> 
  
                      <div class="p-field col-12 mt-2">
                        <h5>Indirizzo sede legale</h5>
                        <hr>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label>Toponimo</label><br>
                        <Dropdown @change="onSelectedToponimo($event, 'legale')" v-model="cliente.toponimo_legale" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" :editable="true"  placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo_legale && submitted}]" @input="onInputToponimo($event, 'legale')"/>
                        <small v-show="validationErrors.toponimo_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label for="indirizzo_legale">Indirizzo</label><br>
                        <InputText @change="onSelected($event)" id="indirizzo_legale" v-model="cliente.indirizzo_legale" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo_legale && submitted}]" @input="(val) => (cliente.indirizzo_legale = cliente.indirizzo_legale.toUpperCase())"/>
                        <small v-show="validationErrors.indirizzo_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label for="civico_legale">Civico</label><br>
                        <InputText @change="onSelected($event)" id="civico_legale" v-model="cliente.civico_legale" :class="['inputfield w-full',{'p-invalid': validationErrors.civico_legale && submitted}]" @input="(val) => (cliente.civico_legale = cliente.civico_legale.toUpperCase())"/>
                        <small v-show="validationErrors.civico_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label for="cap_legale">Cap</label><br>
                        <InputMask mask="99999" @change="onSelected($event)" id="cap_legale" v-model="cliente.cap_legale" :class="['inputfield w-full',{'p-invalid': validationErrors.cap_legale && submitted}]" />
                        <small v-show="validationErrors.cap_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div> 
                      <div class="p-field col-12 xl:col-6">
                        <label>Provincia</label><br>
                        <Dropdown @change="onSelectedDistrict($event, 'legale')" v-model="cliente.provincia_legale" :options="districts" optionLabel="name" :filter="true" optionValue="code" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.provincia_legale && submitted}]" />
                        <small v-show="validationErrors.provincia_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 xl:col-6">
                        <label>Comune</label><br>
                        <Dropdown @change="onSelectedCity($event, 'legale')" v-model="cliente.comune_legale" :options="cities_legale"  :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune_legale && submitted}]" />
                        <small v-show="validationErrors.comune_legale && submitted" class="p-error">Campo richiesto.</small>
                      </div> 
                      <div v-if="selectedTypeClient == 2" class="p-field col-12 flex justify-content-between mt-3">
                        <label><strong>L'indirizzo di spedizione coincide con l'indirizzo della sede legale</strong></label>
                        <Checkbox v-model="sameAddress" :binary="true" @change="setSameAddress"/>
                      </div>
                      <div v-if="!sameAddress" class="grid mt-3">
                        <div class="p-field col-12">
                          <h5>Indirizzo di spedizione</h5>
                          <hr>
                        </div> 
  
                        <div class="p-field col-12 xl:col-6">
                          <label>Toponimo</label><br>
                          <Dropdown @change="onSelectedToponimo($event, 'spedizione')" v-model="contatto.toponimo_spedizione" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" :editable="true"  placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo_spedizione && submitted}]" @input="onInputToponimo($event, 'spedizione')"/>
                          <small v-show="validationErrors.toponimo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div> 
  
                        <div class="p-field col-12 xl:col-6">
                          <label for="indirizzo_spedizione">Indirizzo</label><br>
                          <InputText @change="onSelected($event)" id="indirizzo_spedizione" v-model="contatto.indirizzo_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo_spedizione && submitted}]" @input="(val) => (contatto.indirizzo_spedizione = contatto.indirizzo_spedizione.toUpperCase())"/>
                          <small v-show="validationErrors.indirizzo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label for="civico_spedizione">Civico</label><br>
                          <InputText @change="onSelected($event)" id="civico_spedizione" v-model="contatto.civico_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.civico_spedizione && submitted}]" @input="(val) => (contatto.civico_spedizione = contatto.civico_spedizione.toUpperCase())"/>
                          <small v-show="validationErrors.civico_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label for="cap_spedizione">Cap</label><br>
                          <InputMask mask="99999" @change="onSelected($event)" id="cap_spedizione" v-model="contatto.cap_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.cap_spedizione && submitted}]" />
                          <small v-show="validationErrors.cap_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        
                        <div class="p-field col-12 xl:col-6">
                          <label>Provincia</label><br>
                          <Dropdown @change="onSelectedDistrict($event, 'spedizione')" v-model="contatto.provincia_spedizione" :options="districts" optionLabel="name" :filter="true" optionValue="code" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.provincia_spedizione && submitted}]" />
                          <small v-show="validationErrors.provincia_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>
                        <div class="p-field col-12 xl:col-6">
                          <label>Comune</label><br>
                          <Dropdown @change="onSelectedCity($event, 'spedizione')" v-model="contatto.comune_spedizione" :options="cities_spedizione" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune_spedizione && submitted}]" />
                          <small v-show="validationErrors.comune_spedizione && submitted" class="p-error">Campo richiesto.</small>
                        </div>    
                      </div>                 
                    </div>
                  </TabPanel>
                </TabView>            
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer v-if="visibleStepInfo">
          <div class="grid justify-content-between">
             <!-- <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" /> -->
            <i></i>
            <Button label="Avanti" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer" :disabled="!canGoToNext"/>
          </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated, computed } from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex'
import TreeSelect from 'primevue/treeselect';
import Dialog from 'primevue/dialog';
import moment from 'moment'

export default {

  components: {
    TreeSelect,
    // TabView,
    // TabPanel,
  },

  setup (props, {emit}) {
    const toast = useToast();
    let stepIndex = 0
    // const isIvaAgevolata = ref(false)
    const sameAddress = ref(true)
    const sameCfPiva = ref(true)
    const typesClient = ref([])
    const selectedTypeClient = ref(null)
    const selectedContact = ref(null)
    const offerte = ref([])
    const selectedOfferte = ref([])
    const validationErrors = ref({})
    const submitted = ref(false)
    const selectedClient = ref(null)
    const cliente = ref({})
    const contatto = ref({})
    const activeTabIndex = ref(0)
    const clients = ref([])
    const clienti = ref([])
    const typeSelected = ref('')
    const toponimi = ref([])
    const districts = ref([])
    const cities_legale = ref([])
    const cities_spedizione = ref([])
    const selectedSpedizione = ref('')
    const selectedFrequenza = ref('Mensile')
    const spedizioni = ref([]);
    const frequenze = ref([
      {id: 1, name: 'Mensile'},
      {id: 2, name: 'Bimestrale'}
    ]);
    const contatti = ref([])
    const newContatto = ref(false)
    const sdi = ref([])
    const maskCF = ref()
    const visibleStepInfo = ref()
    const pratica_contratto = ref()
    const isManualContract = ref(false)
    const isIvaAgevolata = ref(false)
    const agents = ref([])
    const selectedAgent = ref()
    const selectedKeyAgent = ref()
    const windowSize = ref(window.innerWidth)
    const isCheckedDataSwitch = ref()
    const date = ref(new Date());
    const minDatePod = ref();
    const minDatePdr = ref();
    
    const maxDatePod = ref();
    const maxDatePdr = ref();
    const dataSwitchPod = ref()
    const dataSwitchPdr = ref()
    const codice_promozionale = ref('')
    const id_promozione = ref()
    const id_cliente_promozione = ref()
    const canGoToNext = ref(true)
    const canViewInputPromo = ref()

    const cities = ref([])
    const selectedTipo = ref(null)
    const selectedTipoName = ref('')
    const tipoCliente = ref([])
    const cod_fiscale = ref('')
    const partita_iva = ref('')
    const nome_domestico = ref('')
    const cognome_domestico = ref('') 
    const toponimo_domestico = ref('') 
    const indirizzo_domestico = ref('') 
    const civico_domestico = ref('') 
    const cap_domestico = ref('') 
    const comune_domestico = ref('') 
    const provincia_domestico = ref('') 

    const rag_sociale = ref('')
    const cod_fiscale_azienda = ref('') 
    const phone = ref('') 
    const cellulare_domestico = ref(null) 
    const email_domestico = ref('')
    const pec = ref('')
    const score = ref('')
    const selectedToponimo = ref(null)
    const address = ref()
    const civico = ref('')
    const cap = ref(null)
    const selectedDistrict = ref(null)
    const selectedCity = ref(null)
    const selectedCityName = ref('')
    const codice_destinatario = ref('')
    const codice_ateco = ref('')
    const note = ref('')

    const store = useStore()
    const currentUser = ref(store.state.auth.user)
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const showModal = ref(false)
    const visible = ref(false)
    const visibleOfferte = ref(false)
    const slides = ref([])

    const setMinDate = () => {
      minDatePod.value = new Date(moment(date.value).add(2, 'months'))
      minDatePdr.value = new Date(moment(date.value).add(3, 'months'))
      dataSwitchPod.value = minDatePod.value

      maxDatePod.value = new Date(moment(date.value).add(8, 'months'))
      maxDatePdr.value = new Date(moment(date.value).add(9, 'months'))
      dataSwitchPdr.value = minDatePdr.value
    }

    onMounted(() => {
      pratica_contratto.value = null
      setMinDate()
      isCheckedDataSwitch.value = true
      setResponsive()
      getSlides()
      setCanViewInputPromo()
      if (currentUser.value.role == 'admin') {
        getAgents()
      }else{
        selectedKeyAgent.value = {0: true}
        selectedAgent.value = currentUser.value.id
        getAgents(currentUser.value.id)
        getOfferte();
      }
      getSpedizioni()
      getToponimi();
      getDistricts();
      getSDI()
      emit("activeLoader", true)
      const promises = [
        getAllTipoClient(),
        getClients()
      ]
      Promise.all(promises).finally(()=>{
        emit("activeLoader", false)
      })
    })
    onUpdated(()=> {
      setResponsive()
      getSlides()
      if (currentUser.value.role == 'admin') {
        getAgents()
      }else{
        getOfferte();
      }

      getAllTipoClient();
      getToponimi();
      getDistricts();
      getSpedizioni()
      getClients();
      getSDI()
    })

    const setResponsive = () => {
      window.addEventListener('resize', () => {
        windowSize.value = window.innerWidth
      })
    }

    const getSlides = async () => {
      await service.getSlides(stepIndex)
      .then((res) => {
        slides.value = res.items
        slides.value.forEach(slide => {
          slide.name = `/images/Step/step${stepIndex}-${slide.order}.png`
        })
      })
    }

    const setCanViewInputPromo = async () => {
      await service.CanViewInputPromo()
      .then((res) => {
        canViewInputPromo.value = res.item
      })
    }

    const onChangeAgent = (evt = null) => {
      delete validationErrors.value.selectedAgent
      delete validationErrors.value.offerte

      if (evt) {
        selectedAgent.value = evt.id
      }
      getOfferteAgent()
    }
    const getAgents = async (id = null) => {
      if (id) {
        await service.getAgentAgency(id)
        .then((res) => {
          agents.value = res.items
        })
      } else {
        service.getAgents().then((res) => {
          agents.value = res.items
        })        
      }
    }

    const onInputCodFis = (e) => {
      cliente.value.codfis = e.target.value.toUpperCase()
      cliente.value.piva = sameCfPiva.value ? cliente.value.codfis : cliente.value.piva
    }
    const onSelectedSdi = () => {
      delete validationErrors.value.sdi;
      delete validationErrors.value.sdi_invalid;
    }
    const getSDI = async () => {
      service.getSDI().then((res) => {
        sdi.value = res.items
      })
    }
    const setSDI = async () => {
      switch (selectedTypeClient.value) {
        case 1:
        case 3:
        case 4:
          await getSDI()
          break;
        default:
          sdi.value = [{sdi:'0000000'}]
          cliente.value.sdi = sdi.value[0] 
          break;
      }
    }
    const onInputToponimo = (evt, type) => {
      if (type == 'legale') {
        cliente.value[`toponimo_${type}`] = evt.target.value.toUpperCase()
      } else {
        contatto.value[`toponimo_${type}`] = evt.target.value.toUpperCase()
      }
      delete validationErrors.value[`toponimo_${type}`]
    }

    const setSameCfPiva = () => {
      if (sameCfPiva.value) {
        cliente.value.piva = cliente.value.codfis
        delete validationErrors.value.piva
        delete validationErrors.value.piva_exist
        delete validationErrors.value.piva_invalid
      }else{
        delete cliente.value.piva
      }
    }
    const setSameAddress = () => {
      let properties_address = [
        'toponimo',
        'indirizzo',
        'civico',
        'cap',
        'provincia',
        'comune'
      ]
      if (sameAddress.value) {
        properties_address.forEach((property)=>{
          contatto.value[`${property}_spedizione`] = cliente.value[`${property}_legale`]
          delete validationErrors.value[`${property}_spedizione`]
        })
        contatto.value.cellulare = cliente.value.cellulare
        contatto.value.email = cliente.value.email
      }else{
        properties_address.forEach((property)=>{
          delete contatto.value[`${property}_spedizione`]
        })
        delete contatto.value.cellulare
        delete contatto.value.email
      }
    }

    const removeExistentClient = () => {
      selectedClient.value = null
      selectedContact.value = null
      contatti.value = []
      validationErrors.value = []
      newContatto.value = false
      Object.keys(cliente.value).forEach(el => {
        delete cliente.value[el]
      });
    }
    const getClientsFromType = (type) =>{
      clienti.value = clients.value.filter(el => el.cod_type_client == type)
    }
    const openPosition = () => {
      visible.value = true;
    }
    const openOfferte = () => {
      visibleOfferte.value = !visibleOfferte.value;
    }

    const getAllTipoClient = async () => {
      // emit("activeLoader", true)
      await service.getAllTipoClient().then((res) => {
        typesClient.value = res.items
        typesClient.value.forEach((el)=>{
          el.selected = false
        })
      })
    }
    const getOfferteAgent = () => {
      service.getOfferteAgent(selectedAgent.value)
      .then((res) => {
        offerte.value = res.items
        selectedOfferte.value = offerte.value
      })
    }
    const getOfferte = () => {
      service.getOfferte()
      .then((res) => {
        offerte.value = res.items
        selectedOfferte.value = offerte.value
      })
    }
    const getSpedizioni = () => {
      service.getSpedizioni()
      .then((res) => {
        res.items.pop()
        spedizioni.value = res.items
        selectedSpedizione.value = res.items[1].name
      })
    }
    const getClients = async () => {
      await service.getClients().then((res) => {
        clients.value = res.items
      })
      // .finally(()=>{
      //   emit("activeLoader", false)
      // })
    }
    const getToponimi = () => {
      service.getToponimi().then((res) => {
        toponimi.value = res.items
      })
    }
    const getDistricts = () => {
      service.getDistricts().then((res) => {
          districts.value = res.items
      }) 
    }
    // const getCities = () => {
    //   service.getCities().then((res) => {
    //       cities.value = res.items
    //   })
    // }

    const removeExClient = () => {
      selectedClient.value = null
      removeErrorPossibleInsert ()
    }

    const removeErrorPossibleInsert = () => {
      delete validationErrors.value['spentPossibleInsert']
    }

    const onSelectedDistrict = (evt, type_district) => {
      service.getCities(evt.value).then((res) => {
        if (type_district == 'legale') {
          cities_legale.value = res.items
        }else{
          cities_spedizione.value = res.items
        }
      })
      if (type_district == 'legale') {
          cliente.value[`comune_${type_district}`] = ''
        }else{
          contatto.value[`comune_${type_district}`] = ''
        }
      delete validationErrors.value[`provincia_${type_district}`];
    }
    const onSelectedToponimo = (evt, type) => {
      delete validationErrors.value[`toponimo_${type}`];
    }

    const onSelectedExistentClient = () => {
      delete validationErrors.value['existentClient'];
      newContatto.value = false
      selectedContact.value = null
      contatti.value = []
      contatto.value = {}
      if (selectedTypeClient.value == 2) {
        getContatti()
      }
    }
    const getContatti = () => {
      service.getContacts(selectedClient.value.id, 'cliente').then((res) => {
        contatti.value = res.items.filter(el => el.email)
        contatti.value = contatti.value.filter(el => el.cell)
        newContatto.value = contatti.value.length == 0 ? true : false
        selectedContact.value = contatti.value.length == 0 ? null : contatti.value[contatti.value.length-1]
      })
    }
    
    const onSelectedCity = (evt, type_city) => {
      if (type_city == 'legale') {
        cliente.value[`comune_${type_city}`] = evt.value
      }else{
        contatto.value[`comune_${type_city}`] = evt.value
      }
      delete validationErrors.value[`comune_${type_city}`];
    }
    const onSelectedContact = () => {
      delete validationErrors.value.contact;
    }
    const onSelectedMobile = (evt) => {
      cliente.value.cellulare = evt.target.value;
      showModal.value = true;
      delete validationErrors.value['cellulare_incorrect'];
      delete validationErrors.value['cellulare'];
      delete validationErrors.value['noTelCell'];
    }
    const onSelectedTelefono = (evt) => {
      cliente.value.telefono = evt.target.value;
      delete validationErrors.value['cellulare_incorrect'];
      delete validationErrors.value['cellulare'];
      delete validationErrors.value['noTelCell'];
    }
    const onSelected = (evt) => {
      delete validationErrors.value[evt.target.id];
      if (evt.target.id == 'email') {
        delete validationErrors.value['isEmail'];
      }else if(evt.target.id == 'codfis'){
        delete validationErrors.value['codfis_invalid'];
        delete validationErrors.value['codfis_exist'];
        if (sameCfPiva.value) {
          delete validationErrors.value['piva_invalid'];
          delete validationErrors.value['piva_exist'];
        }
      }else if(evt.target.id == 'piva'){
        delete validationErrors.value['piva_invalid'];
        delete validationErrors.value['piva_exist'];
      }
    }
    const isCorrect = (is_correct = false) => {
      if (!is_correct) {
        validationErrors.value['cellulare_incorrect'] = true;
      }else{
        delete validationErrors.value['cellulare_incorrect'];
      }
      showModal.value = false;
    }

    const setData = (el) => {
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-01`
      return el
    } 

    const nextPage = async () => {
      submitted.value = true;
      if (Object.keys(validationErrors.value).length > 0) {
        toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3000});
      }else if (await validateForm()) {
        emit("activeLoader", true)
        if (selectedTypeClient.value == 2) {
          stepIndex = 1
        }
        emit('next-page', {
          formData: 
          {
            "isIvaAgevolata": isIvaAgevolata.value,
            "spedizione": selectedSpedizione.value,
            "frequenza": selectedFrequenza.value,
            "contratto_manuale": isManualContract.value,
            "typeClient": selectedTypeClient.value,
            "offerte": selectedOfferte.value,
            "cliente": cliente.value,
            "contatto": contatto.value,
            "pratica": pratica_contratto.value,
            "agente": selectedAgent.value,
            "prima_data_utile": isCheckedDataSwitch.value,
            "data_switch_pod": isCheckedDataSwitch.value ? null : setData(dataSwitchPod.value),
            "data_switch_pdr": isCheckedDataSwitch.value ? null : setData(dataSwitchPdr.value),
            'id_promozione': id_promozione.value,
            'id_cliente_promozione': id_cliente_promozione.value
          }
          ,pageIndex: stepIndex
        });
      }
    }
    const prevPage = () => {
      emit('prev-page', {pageIndex: stepIndex});
    }

    const validateForm = async () => {
      validationErrors.value = []
      if (currentUser.value.role == 'admin') {
        if (selectedAgent.value == null || selectedAgent.value == '') {
          validationErrors.value['selectedAgent'] = true;
        } else {
          delete validationErrors.value['type_client'];
        }
      }
      
      
      if (selectedTypeClient.value == null)
        validationErrors.value['type_client'] = true;
      else 
        delete validationErrors.value['type_client'];

      if (selectedSpedizione.value == null)
        validationErrors.value['spedizione'] = true;
      else 
        delete validationErrors.value['spedizione'];

      if (offerte.value.length == 0) {
        validationErrors.value['offerte'] = true;
      }else{
        delete validationErrors.value['offerte'];
      }
  
      if (selectedTypeClient.value != 3) {
        if (activeTabIndex.value == 0) {
          if (selectedClient.value != null) {
            cliente.value.id = selectedClient.value.id
            cliente.value.codfis = selectedClient.value.codfis
            cliente.value.piva = selectedClient.value.piva
            cliente.value.nome = selectedClient.value.nome
            cliente.value.cognome = selectedClient.value.cognome
            cliente.value.ragsoc = selectedClient.value.ragsoc
            cliente.value.sdi = selectedClient.value.codiceDestinatario
            cliente.value.toponimo_legale = selectedClient.value.toponimo
            cliente.value.indirizzo_legale = selectedClient.value.indirizzo
            cliente.value.civico_legale = selectedClient.value.civico
            cliente.value.comune_legale = selectedClient.value.cod_comune
            cliente.value.cap_legale = selectedClient.value.cap
            cliente.value.rapp = selectedClient.value.cod_rapp

            delete validationErrors.value['existentClient']
            if (selectedTypeClient.value == 2) {
              if (contatti.value.length != 0 && !newContatto.value) {
                if (selectedContact.value == null) {
                  validationErrors.value['contact'] = true;
                  return false                
                }else{
                  contatto.value.id = selectedContact.value.id
                }
              }else{
                let validate_properties_contatto = [
                  'email',
                  'cellulare',
                  'toponimo_spedizione',
                  'indirizzo_spedizione',
                  'civico_spedizione',
                  'cap_spedizione',
                  'provincia_spedizione',
                  'comune_spedizione'
                ]
                Object.keys(contatto.value).forEach(element => {
                  if(!element.includes('comune')){
                    contatto.value[element] = contatto.value[element].trim()
                  }
                });
                validate_properties_contatto.forEach(property => {
                  if (Object.keys(contatto.value).includes(property) && contatto.value[property] != '') {
                    delete validationErrors.value[property]
                  } else {
                    validationErrors.value[property] = true
                  }
                });
              }
            }
          }else{
            validationErrors.value['existentClient'] = true;
          }
        }else{
          await setErrorExistentCfPiva('cod-fiscale', 'codfis')
          if (selectedTypeClient.value != 2) {
            await setErrorExistentCfPiva('piva', 'piva')
          }

          let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          let regex_codfis = selectedTypeClient.value == 2 || selectedTypeClient.value == 4 ? /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i : /^[0-9]{11}$/
          let regex_piva = /^[0-9]{11}$/
          // let regex_alphanumeric = /[a-zA-Z]/
  
          let validate_properties_cliente = [
            'codfis',
            'email',
            // 'cellulare',
            'toponimo_legale',
            'indirizzo_legale',
            'civico_legale',
            'cap_legale',
            'provincia_legale',
            'comune_legale',
            'sdi'
          ]
          let validate_properties_contatto = []

          if (selectedTypeClient.value != 2) {
            validate_properties_cliente.push('ragsoc')
          } else {
            validate_properties_cliente.push('nome','cognome','cellulare')   
          }

          if (!sameCfPiva.value) {
            validate_properties_cliente.push('piva')
          }

          if (!sameAddress.value) {
            validate_properties_contatto.push(
              'toponimo_spedizione',
              'indirizzo_spedizione',
              'civico_spedizione',
              'cap_spedizione',
              'provincia_spedizione',
              'comune_spedizione'
            )
            contatto.value.email = cliente.value.email
            contatto.value.cellulare = cliente.value.cellulare
          }else{
            validate_properties_cliente.forEach(property => {
              let property_contatto = property.includes('legale') ? property.replace("_legale", "_spedizione") : property
              switch (property) {
                case 'codfis':
                case 'nome':
                case 'cognome':
                  break;
              
                default:
                contatto.value[property_contatto] = cliente.value[property]
                  break;
              }
            });
          }
          Object.keys(cliente.value).forEach(element => {
            if(!element.includes('comune') && !element.includes('sdi')){
              if (cliente.value[element] != null) {
                cliente.value[element] = cliente.value[element].trim()
              }
            }
          });
          validate_properties_cliente.forEach(property => {
            if (Object.keys(cliente.value).includes(property) && (cliente.value[property] != '' && cliente.value[property] != null)) {
              delete validationErrors.value[property]
              if (property == 'email') {
                if (!regex_email.test(cliente.value.email)){
                  validationErrors.value['isEmail'] = true;
                }else{
                  delete validationErrors.value['isEmail'];
                }
              }else if(property == 'codfis' || property == 'piva'){
                let regex = eval(`regex_${property}`);
                if(!regex.test(cliente.value[property])){
                  validationErrors.value[`${property}_invalid`] = true;
                }else{                  
                  if (property == 'piva' || (property == 'codfis' && ![2,4].includes(selectedTypeClient.value))) {
                    let incorrect_pi = []
                    for (let index = 0; index <= 9; index++) {
                      let pi = '';
                      for (let y = 0; y < 11; y++) {
                        pi += index
                      }
                      incorrect_pi.push(pi)
                    }
                    if (incorrect_pi.includes(cliente.value[property])) {
                      validationErrors.value[`${property}_invalid`] = true;                      
                    }else{
                      delete validationErrors.value[`${property}_invalid`]
                    }
                  }else{
                    delete validationErrors.value[`${property}_invalid`]
                  }
                }
              }else if(property == 'sdi'){
                if (selectedTypeClient.value == 5) {
                  if (cliente.value[property] == '' || cliente.value[property] == null) {
                    validationErrors.value[`${property}`] = true;                    
                  } else {
                    delete validationErrors.value[`${property}`];
                    if(cliente.value[property].length != 6){
                      validationErrors.value[`${property}_invalid`] = true;
                    }else{
                      delete validationErrors.value[`${property}_invalid`];
                    }                    
                  }
                } 
              }
            } else {
              validationErrors.value[property] = true
            }
          });
          if (selectedTypeClient.value != 2) {
            if ((!Object.keys(cliente.value).includes('telefono') || cliente.value['telefono'] == '' || cliente.value['telefono'] == null) && (!Object.keys(cliente.value).includes('cellulare') || cliente.value['cellulare'] == '' || cliente.value['cellulare'] == null)) {
              validationErrors.value.noTelCell = true
            }else{
              delete validationErrors.value['noTelCell'];
            }
          }

          validate_properties_contatto.forEach(property => {
            if (Object.keys(contatto.value).includes(property) && contatto.value[property] != '') {
              delete validationErrors.value[property]
            } else {
              validationErrors.value[property] = true
            }
          });
        }
      }
      return !Object.keys(validationErrors.value).length;
    }
    const resetStepIndex = async () => {
      // await setSDI()
      activeTabIndex.value = 0
      sameCfPiva.value = true
      sameAddress.value = true
      selectedClient.value = null
      cliente.value = {}
      validationErrors.value = []
      newContatto.value = false
      getClientsFromType(selectedTypeClient.value)
      stepIndex = 0
      delete validationErrors.value['type_client'];
      delete validationErrors.value['offerte'];
      let container_form = document.getElementById("form-cliente")
      if (selectedTypeClient.value != 3) {
        container_form.style.display='block'
        switch (selectedTypeClient.value) {
          case 1:
          case 5:
          case 6:
            maskCF.value = '99999999999'
            break;
          case 2:
            maskCF.value = 'aaaaaa99a99a999a'
          break;
          case 4:
            sameCfPiva.value = false  
            maskCF.value = 'aaaaaa99a99a999a'
            break;
        }
      }else{
        container_form.style.display='none'
      }
      typeSelected.value = typesClient.value.find(type => type.id == selectedTypeClient.value).name
    }
    const onChangeTypeClient = async (id) => {
      activeTabIndex.value = 0
      sameCfPiva.value = true
      sameAddress.value = true
      selectedClient.value = null
      cliente.value = {}
      validationErrors.value = []
      newContatto.value = false
      getClientsFromType(id)
      stepIndex = 0
      delete validationErrors.value['type_client'];
      delete validationErrors.value['offerte'];
      let container_form = document.getElementById("form-cliente")
      let index = typesClient.value.findIndex(type => type.id == id)
      typesClient.value.forEach((el, i)=>{
        el.selected = i == index ? true : false
      })
      typeSelected.value = typesClient.value.find(type => type.id == id).name
      selectedTypeClient.value = id

      if (id != 3) {
        container_form.style.display='block'
        switch (id) {
          case 1:
          case 5:
          case 6:
            maskCF.value = '99999999999'
            break;
          case 2:
            maskCF.value = 'aaaaaa99a99a999a'
          break;
          case 4:
            sameCfPiva.value = false  
            maskCF.value = 'aaaaaa99a99a999a'
            break;
        }
      }else{
        container_form.style.display='none'
        await nextPage()
      }

    }
    const setErrorExistentCfPiva = async (check, property) => {
      let existentClient = await validateExistentCfPiva(check, property)
      if (existentClient.items) {
        validationErrors.value[`${property}_exist`] = true;
      }
    }
    const validateExistentCfPiva = async (check, property) => {
      emit("activeLoader", true)
      return service.checkExistentClient(cliente.value[property], check, selectedTypeClient.value)
      .finally(() => {
        emit("activeLoader", false)
      })         
    }
    const setNewContatto = () => {
      delete validationErrors.value.contact
      contatto.value = {}
      let properties_address = [
        'cellulare',
        'email',
        'toponimo_spedizione',
        'indirizzo_spedizione',
        'civico_spedizione',
        'cap_spedizione',
        'provincia_spedizione',
        'comune_spedizione',
      ]
      if (newContatto.value) {
        delete validationErrors.value.contact
        selectedContact.value = null
      }else{
        properties_address.forEach(property => {
          delete cliente.value[`${property}`]
        });
      }
    }
    const onSelectedView = () => {
      if (activeTabIndex.value == 0) {
        Object.keys(validationErrors.value).forEach((error) => {
          delete validationErrors.value[`${error}`]
        });
      }else{
        delete validationErrors.value.existentClient
        if (selectedTypeClient.value == 2) {
          cliente.value.sdi = '0000000'
        }
      }
    }
    const setNewContract = (pratica = null) => {
      visibleStepInfo.value = true
      pratica_contratto.value = pratica
    }

    const verificaCodicePromozionale = async (inReset = true) => {
      if (codice_promozionale.value) {
        await service.checkCodicePromozionale(codice_promozionale.value)
        .then((res) => {
          if (!res.items.success && inReset) {
            codice_promozionale.value = ''
          }
          id_promozione.value = res.items.id_promozione
          id_cliente_promozione.value = res.items.id_cliente_promozione 
          let severity = res.items.success ? 'success' : 'error'
          let detail = res.items.msg
          toast.add({severity: severity, detail: detail, life: 5000})
          canGoToNext.value = inReset ? inReset : res.items.success
        })
      }
    }
    const checkCanGoToNext = (evt) => {     
      canGoToNext.value = evt.target.value == '' 
    }

    const canViewButtonCheckCodicePromozionale = computed(() => {
      return codice_promozionale.value != '' && codice_promozionale.value != null
    })


    return {
      activeTabIndex,
      toponimi,
      districts,
      cities_legale,
      cities_spedizione,
      selectedClient,
      removeExClient,
      clients,
      removeErrorPossibleInsert,
      onSelectedDistrict,
      onSelectedToponimo,
      onSelectedCity,
      // isIvaAgevolata,
      selectedTypeClient,
      selectedContact,
      typesClient,
      offerte,
      selectedOfferte,
      submitted,
      nextPage,
      prevPage,
      validationErrors,
      resetStepIndex,
      cliente,
      contatto,
      selectedSpedizione,
      selectedFrequenza,
      spedizioni,
      frequenze,
      onSelectedMobile,
      onSelectedTelefono,
      showModal,
      isCorrect,
      onSelected,
      openPosition,
      visible,
      slides,
      clienti,
      removeExistentClient,
      onSelectedExistentClient,
      sameAddress,
      sameCfPiva,
      setSameCfPiva,
      setSameAddress,
      onInputCodFis,
      onInputToponimo,
      typeSelected,
      contatti,
      newContatto,
      setNewContatto,
      onSelectedContact,
      onSelectedView,
      onSelectedSdi,
      sdi,
      openOfferte,
      visibleOfferte,
      maskCF,
      setNewContract,
      visibleStepInfo,
      isManualContract,
      isIvaAgevolata,
      onChangeTypeClient,
      currentUser,
      agents,
      selectedAgent,
      selectedKeyAgent,
      onChangeAgent,
      windowSize,
      dataSwitchPod,
      dataSwitchPdr,
      isCheckedDataSwitch,
      date,
      minDatePod,
      maxDatePod,
      minDatePdr,
      maxDatePdr,
      moment,
      verificaCodicePromozionale,
      codice_promozionale,
      canViewButtonCheckCodicePromozionale,
      canGoToNext,
      checkCanGoToNext,
      canViewInputPromo,
    }
  },
}
</script>

<style>
  .btn-tutorial{
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .form-step .p-card-title, .form-step .p-card-subtitle {
      text-align: center;
  }
  .btn-remove-selected {
    cursor: pointer;
  }
  #form-cliente .p-tabview-nav{
    flex-wrap: wrap !important;
  }

</style>
<style scoped>
  .btn-filter{
    border-radius: 20px;
    width: 140px;
    height: 75px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }
  .btn-filter:hover {
    opacity: 1;
  }

  .btn-filter.selected {
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .bg-warning{
    background-color: #ffe034;
  }
  .btn-offerte{
    border-radius: 50% !important;
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .btn-offerte:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border-color: #2196F3;
  }

  .p-card{
    min-width: 335px !important;
  }
  .badge-offerta {
    display: inline-block !important;
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    padding: 5px 7px;
    margin: 3px;
    font-size: 12px;
    border-radius: 3px;
  }
  .btn-big{
    /* width: 25%; */
    /* min-width: 200px;
    min-height: 110px; */
    width: 280px;
    height: 130px;

    aspect-ratio: 4/1.7;
    margin: 5px;
    border-radius: 20px;
    font-size: x-large;
  }

</style>

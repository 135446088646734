<template>
<Loader v-if="loading" />
  <h4>Validazione Documentale</h4>

  <div class="counter grid mb-2">
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0" style="padding: 12px 20px;">
        <div class="flex justify-content-between mb-0">
          <div class="col-5">
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Contratti da validare: </span>
              <div class="text-900 font-medium text-xl">{{ contatori['contratti_validazione'] }}</div>
            </div>
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Contratti in KO:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['contratti_ko'] }}</div>
            </div>
            <div class="flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Totale Contratti:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['totale_contratti'] }}</div>
            </div>
          </div>

          <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2rem;height:2rem;">
            <i class="pi pi-file text-blue-500 text-m"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0" style="padding: 12px 20px;">
        <div class="flex justify-content-between mb-0">
          <div class="col-5">
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Pod da validare: </span>
              <div class="text-900 font-medium text-xl">{{ contatori['pod_validazione'] }}</div>
            </div>
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Pod in KO:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['pod_ko'] }}</div>
            </div>
            <div class="flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Totale Pod:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['totali_pod'] }}</div>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2rem;height:2rem;">
            <i class="pi pi-flag text-yellow-500 text-m"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div class="card mb-0" style="padding: 12px 20px;">
        <div class="flex justify-content-between mb-0">
          <div class="col-5">
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Pdr da validare: </span>
              <div class="text-900 font-medium text-xl">{{ contatori['pdr_validazione'] }}</div>
            </div>
            <div class="mb-3 flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Pdr in KO:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['pdr_ko'] }}</div>
            </div>
            <div class="flex justify-content-between align-items-center">
              <span style="width: 135px;" class="text-500 font-medium">Totale Pdr:</span>
              <div class="text-900 font-medium text-xl">{{ contatori['totali_pdr'] }}</div>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2rem; height: 2rem;">
            <i class="pi pi-flag text-grey-500 text-m"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mb-2">
    <div class="col-12 xl:col-9">
      <div class="col-12">Filtra per stato caricamento</div>
      <div class="card mb-0 flex flex-wrap justify-content-between p-1">
        <div class="col-12 md:col-6 xl:col-2" v-for="stato in stati" :key="stato">
          <Button :label="stato.name.toUpperCase()" :class="`w-full btn-filter p-button-${getSeverity(stato.name)} ${stato.selected ? 'selected' : ''}`" @click="onChangeFilter(stato.key)" style="height: 35px;"/>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="col-12">Filtra per data caricamento</div>
      <div class="card mb-0 flex justify-content-center p-1" style="height: 55px;">
        <div class="col-12">
          <Calendar ref="calendarRef" v-model="selectedDate" :maxDate="maxDate" selectionMode="range" :manualInput="false" @update:modelValue="onChangeDate($event)" dateFormat="dd/mm/yy" class="w-full" style="height: 35px;"/>
        </div>
      </div>
    </div>
  </div>

  <div class="card caricamenti-multisito" v-if="items && items.length > 0">
    <DataTable :value="items" sortField="id" dataKey="id" :sortOrder="-1" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" breakpoint="1630px" v-model:expandedRows="expandedRows" v-model:filters="filters" :globalFilterFields="['agente']" filterDisplay="row">
      <Column style="width: 6rem">
        <template #body="{data}">
          <SplitButton icon="pi pi-list" v-tooltip="`Procedi alla validazione`" @click="onRowExpand({data: data})" :model="getActions(data)">
          </SplitButton>
        </template>
      </Column>
      <Column field='id' header="#" :showFilterMenu="false" :filterMenuStyle="{ width: '8rem' }" style="width: 100px">
        <template #body="{data}">
          <span>#{{data.id}}</span>
        </template>
      </Column>
      <Column field='type_firma' header="Tipologia Contratto" style="width: 7%;">
        <template #body="{data}">
          <strong>{{ (data.is_contratto_manuale ? 'Cartaceo' : 'Digitale') + (data.pratica ? ' con ' + data.pratica : '') }}</strong>
        </template>
      </Column>
      <Column field="agente" header="Autore" :showFilterMenu="false" :filterMenuStyle="{ width: '12rem' }" style="width: 15%">
        <template #body="{data}">
          <span v-if="data.admin_id != 0">
          {{data.admin}} <small>(admin)</small>
          </span>
          <br>
          <span v-if="data.agente_id != 0">
            <Button class="p-button-link" @click="goTo($event, data)">
              <p>
                {{data.agente}} <small>(agente {{data.agenteMaster != data.agente ? `${data.agenteMaster}` : 'Master'}})</small>
              </p>
            </Button>
          </span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="authors" placeholder="Seleziona un agente" class="p-column-filter" style="min-width: 12rem" :showClear="true">
            <template #option="slotProps">
              <div class="flex align-items-center gap-2">
                <span>{{ slotProps.option }}</span>
              </div>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column header="Rappresentante - Cliente" style="width: 140px;">
          <template #body="{data}">
          <span v-if="data.rappresentante">{{data.rappresentante}}</span>
          <span v-else>{{data.cliente}}</span>
        </template>
      </Column>
      <Column field='data_caricamento' header="Data" :sortable="true" style="width: 100px;">
        <template #body="{data}">
          {{moment(data.data_caricamento).format('DD-MM-YYYY HH:mm')}}
        </template>
      </Column>
      <Column  field='type' header="Tipo" :sortable="true" style="width: 80px;">
        <template #body="{data}">
          <span v-if="data.type === 'ee'">Energia</span>
          <span v-else-if="data.type === 'gas'">Gas</span>
          <span v-else>Energia & Gas</span>
        </template>
      </Column>
      <Column field='total_rows' header="Righe" style="width: 65px;"/>
      <Column field='notes' header="Note interne" style="width: 15%;">
        <template #body="{data}">
          <div class="flex flex-column justify-content-between">
            <Textarea placeholder="Aggiungi nuova nota" v-model="data.new_note_interne" autoResize rows="3" cols="25" class="mb-3"/>
            <div v-if="data.note_interne" class="grid flex-column">
              <li v-for="(nota, index) in data.note_interne" :key="nota" class="flex justify-content-between nota">
                <i @click="deleteNota(index)" class="pl-0 col-1 btn-remove-selected p-error pi pi-times" style="font-size: 1.2rem" v-tooltip="'Rimuovi nota'"></i>
                <div class="col-4">
                  {{ moment(nota.created_at).format('DD-MM-YYYY') }}:
                </div>
                <div class="col-7">
                  <strong>{{ nota.nota }}</strong>
                </div>
              </li>
            </div>
          </div>
        </template>
      </Column>
      <Column header="" style="width: 70px;vertical-align: top;">
        <template #body="{data}" style="font-size: 10px">
          <div class="flex flex-column"> 
            <Button @click="toggle($event, data.id)" v-tooltip="`Visualizza Documenti`" type="button" icon="pi pi-eye" iconPos="left" class="p-button-rounded p-button-success"/>  
              
            <OverlayPanel ref="op" @hide="hideOverlay" class="overlayDocs">
              <span class="font-medium text-900 block mb-2">{{loadingFilename ? 'Download in corso...' : ''}}</span>
              <div v-if="loadingFilename == false">
                <div class="flex flex-wrap flex-column" v-if="filesView != null && filesView.length != 0 ">
                  <span style="width: fit-content" class="font-medium text-900 block mb-2">Scarica file</span>
                  <Button v-for="file in filesView" :key="file.filename" @click="viewDocumento(file.matrice_id, file.filename)" :label="file.filename" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-info m-2"/>
                </div>
                <div v-else>
                  <span class="font-medium text-900 block mb-2">Non ci sono file da visualizzare</span>
                </div>
              </div>
            </OverlayPanel>
            <Button v-if="data.new_note_interne" v-tooltip="`Salva note`" icon="pi pi-pencil" class="p-button-rounded p-button-info" @click="saveNotes(data.id, data.new_note_interne)" />
          </div>
        </template>
      </Column> 
      <template #expansion="slotProps">
        <div class="col-12">
          <Fieldset class="mb-2">
            <template #legend>
              Dati client{{ slotProps.data.cliente_id.split(',').length > 1 ? 'i' : 'e' }}
            </template>
            <div class="flex flex-wrap justify-content-between">
              <div v-for="(cliente_id) in slotProps.data.cliente_id.split(',')" :key="cliente_id" class="col-3 m-3" style="border-bottom: 1px solid #dee2e6; border-right: 1px solid #dee2e6;border-radius: 10px">
                <div class="flex align-items-center">
                  <label>Denominazione</label>
                  <p class="m-2">
                    <strong>
                      {{ clienti[cliente_id].denominazione }}
                    </strong>
                  </p>
                </div>
                <div class="flex align-items-center">
                  <label>Codice fiscale</label>
                  <p class="m-2">
                    <strong>
                      {{ clienti[cliente_id].cf }}
                    </strong>
                  </p>
                </div>
                <div class="flex align-items-center" v-if="!['2','3'].includes(clienti[cliente_id].tipologia)">
                  <label>Partita Iva</label>
                  <p class="m-2">
                    <strong>
                      {{ clienti[cliente_id].piva }}
                    </strong>
                  </p>
                </div>
                <div class="flex align-items-center" v-if="!['2'].includes(clienti[cliente_id].tipologia)">
                  <label>Codice Ateco</label>
                  <InlineMessage class="m-2" :severity="getSeverityAteco(clienti[cliente_id].ateco)">{{clienti[cliente_id].ateco ?? 'da ricercare'}}</InlineMessage>
                </div>
                <div class="flex align-items-center"  v-if="clienti[cliente_id].tipologia == '2'">
                  <label>Età</label>
                  <InlineMessage class="m-2" :severity="getSeverityEta(slotProps.data.dati_cliente.eta)">{{slotProps.data.dati_cliente.eta > 0 ? slotProps.data.dati_cliente.eta : 'Verifica il codice fiscale'}}</InlineMessage>
                </div>
                <div class="flex align-items-center" v-if="slotProps.data.codice_promo">
                  <label>Codice promozionale utilizzato</label>
                  <p class="m-2">
                    <strong>"{{slotProps.data.codice_promo }}"</strong> di <strong>{{ slotProps.data.cliente_promotore }}</strong>
                  </p>
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="Check Documenti" class="mb-2">
            <div class="flex">
              <div class="flex flex-wrap col-10" v-if="slotProps.data.step_validazione == 0">
                <div class="flex align-items-center m-2">
                  <Checkbox v-model="slotProps.data.has_documento_identita" :binary="true" :id="`documento_identita_${slotProps.data.id}`"/>
                  <label :for="`documento_identita_${slotProps.data.id}`" class="ml-2"> Documento d'identità presente </label>
                </div>
                <div class="flex align-items-center m-2" v-if="slotProps.data.is_contratto_manuale">
                  <Checkbox v-model="slotProps.data.has_contratto" :binary="true" :id="`contratto_${slotProps.data.id}`"/>
                  <label :for="`contratto_${slotProps.data.id}`" class="ml-2">Contratto presente</label>
                </div>
                <div class="flex align-items-center m-2" v-if="slotProps.data.pratica">
                  <Checkbox v-model="slotProps.data.has_titolo_giust" :binary="true" :id="`tg_${slotProps.data.id}`"/>
                  <label :for="`tg_${slotProps.data.id}`" class="ml-2">Titolo giustificativo presente</label>
                </div>
              </div>
              <div v-else class="flex justify-content-around col-10">
                <div :class="[`${slotProps.data.has_documento_identita ? 'color-green' : 'color-red'}`]">Documento d'identità {{ slotProps.data.has_documento_identita ? 'presente' : 'assente' }}</div>
                <div v-if="slotProps.data.is_contratto_manuale" :class="[`${slotProps.data.has_contratto ? 'color-green' : 'color-red'}`]">Contratto cartaceo {{ slotProps.data.has_contratto ? 'presente' : 'assente' }}</div>
                <div v-if="slotProps.data.pratica" :class="[`${slotProps.data.has_titolo_giust ? 'color-green' : 'color-red'}`]">Titolo giustificativo {{ slotProps.data.has_titolo_giust ? 'presente' : 'assente' }}</div>
              </div>

              <div class="flex align-items-center flex-column col-2" v-if="slotProps.data.step_validazione == 0">
                <div>
                  <Button icon="pi pi-angle-down" class="p-button-rounded" v-tooltip="`Avanti`" @click="goToNextStep('documenti', slotProps.data)"/>
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="Check Credito" class="mb-2" v-if="slotProps.data.step_validazione > 0">
            <div class="flex" v-if="slotProps.data.steps.credito.validabile">
              <div class="col-10 flex flex-column">
                <div v-for="(cliente_id) in slotProps.data.cliente_id.split(',')" :key="cliente_id">
                  <div class="flex justify-content-between col-12 card" v-if="slotProps.data.step_validazione == 1">
                    <div>
                      <h6 class="m-2">{{ clienti[cliente_id].denominazione }}</h6>
                      <div class="flex align-items-center m-2">
                        <Checkbox v-model="credit[cliente_id]" :binary="true" :id="`credit_ee_${cliente_id}`" @change="setCreditCheckBox(cliente_id)"/>
                        <label class="ml-2" :for="`credit_ee_${cliente_id}`">Credit check KO</label>
                      </div>
                    </div>
                    <div class="m-2 card" v-if="slotProps.data.loaded_credit_check"  style="width: fit-content;">
                      <div v-if="slotProps.data.has_credit_check" class="flex flex-wrap">
                        <div class="col-12 flex justify-content-around align-items-center">
                          <Button :class="[`p-button-rounded m-2 traffic-light-active p-button-${getLight(slotProps.data.credit_check_response.trafficLight)}`]" disabled>{{ slotProps.data.credit_check_response.classDescription }}</Button>
                          <div>
                            <label>Score:</label>
                            <p><strong style="font-size: 2.5rem;">{{ slotProps.data.credit_check_response.score }}</strong><span>/100</span></p>
                          </div>
                        </div>
                        <div class="w-full flex justify-content-center col-12">
                          <label class="mr-1">Aggiornato al:</label>
                          <p>{{ moment(slotProps.data.credit_check_response.created_at).format('DD-MM-Y')}} ore {{ moment(slotProps.data.credit_check_response.created_at).format('H:m') }}</p>
                        </div>
                      </div>
                      <div v-else>
                        {{slotProps.data.credit_check_response.errorDescription}}
                      </div>
                    </div>
                    <div class="m-2 flex align-items-center justify-content-center">
                      <Button v-if="!slotProps.data.loaded_credit_check" @click="callCreditCheck(slotProps.data, cliente_id)">Vedi affidabilità</Button>
                      <Button v-else @click="callCreditCheck(slotProps.data, cliente_id, true)">Aggiorna affidabilità</Button>
                    </div>
                  </div>
                  <div class="col-12 flex justify-content-around align-items-center" v-else>
                    <h6 class="m-0 p-0">{{ clienti[cliente_id].denominazione }}</h6>
                    <div :class="[`${!credit[cliente_id] ? 'color-green' : 'color-red'}`]">Credit check {{ !credit[cliente_id] ? '' : 'non' }} superato</div>
                  </div>
                </div>
              </div>
              <div class="flex justify-content-center align-items-center flex-column col-2" v-if="slotProps.data.step_validazione == 1">
                <div>
                  <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`indietro`" @click="goToPrevStep(slotProps.data)"/>
                </div>
                <div style="height: 1rem"></div>
                <div>
                  <Button icon="pi pi-angle-down" class="p-button-rounded" v-tooltip="`Avanti`" @click="goToNextStep('credito', slotProps.data)"/>
                </div>
              </div>
            </div>
            <div v-else class="flex justify-content-center align-items-center" style="height: 80px">
              Non validabile
            </div>
          </Fieldset>

          <Fieldset legend="Forniture" class="mb-2" v-if="slotProps.data.step_validazione > 1">
            <div class="flex"  v-if="slotProps.data.steps.forniture.validabile">
              <div class="col-10">
                <div class="col-12" v-for="type_Matrice in typesMatrice" :key="type_Matrice.val">
                  <div v-if="slotProps.data.forniture[type_Matrice.val]">
                    <h4>{{type_Matrice.name}}</h4>
                    <DataTable v-if="slotProps.data.step_validazione == 2" :value="slotProps.data.forniture[type_Matrice.val]" :alwaysShowPaginator="false" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" editMode="cell" >
                      <Column field="denominazione_cliente" header="Cliente" style="width: 20%"></Column>
                      <Column :field="type_Matrice.device" :header="type_Matrice.device.toUpperCase()" style="width: 20%">
                        <template #body="{data}">
                          <h6 class="m-2"><strong>{{ data.pod ?? data.pdr }}</strong></h6>
                          <div class="m-2">
                            <div class="flex">
                              <label class="mr-2">Data inizio fornitura:</label><strong>{{ data.data_inizio ? moment(data.data_inizio).format('DD/MM/YYYY'): 'non presente'}}</strong>
                            </div>
                            <div class="flex">
                              <label class="mr-2">Data fine fornitura:</label><strong>{{ data.data_fine ? moment(data.data_fine).format('DD/MM/YYYY') : 'non presente'}}</strong>
                            </div>
                          </div>
                          <div class="flex flex-wrap">
                            <div class="flex align-items-center m-2">
                              <Checkbox v-model="data.active" :binary="true" :id="`active_${type_Matrice.val}_${data.id}`"/>
                              <label class="ml-2" :for="`active_${type_Matrice.val}_${data.id}`"> É già in fornitura </label>
                            </div>
                          </div>
                          <div v-if="data.active && data.data_inizio">
                            <div class="flex flex-wrap">
                              <div class="flex align-items-center m-2">
                                Totale fatturato: € {{ formatCurrency(data.totaleFatturato.toFixed(2)) }}
                              </div>
                            </div>
                            <div class="flex flex-wrap">
                              <div class="flex align-items-center m-2">
                                Totale saldo: € {{ formatCurrency(data.totaleSaldo.toFixed(2)) }}
                              </div>
                            </div>
                            <div class="flex flex-wrap">
                              <div class="flex align-items-center m-2">
                                Saldo ultimo mese: € {{formatCurrency(data.saldoUltimoMese.toFixed(2))}}
                              </div>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column header="Offerta" style="width: 20%">
                        <template #body="{data}">
                          {{data.nome_offerta}}
                        </template>
                      </Column>
                      <Column header="Check Documento" style="width: 20%">
                        <template #body="{data}">
                          <div class="card flex flex-wrap">
                            <div class="flex align-items-center m-2">
                              <Checkbox v-model="data.has_ft" :binary="true" :id="`has_ft_${type_Matrice.val}_${data.id}`" @change="setInValidation(slotProps.data)"/>
                              <label class="ml-2" :for="`has_ft_${type_Matrice.val}_${data.id}`"> Fattura presente</label>
                            </div>
                            <div class="flex align-items-center m-2" v-if="slotProps.data.pratica == 'VOLTURA' && slotProps.data.is_contratto_manuale">
                              <Checkbox v-model="data.has_v1" :binary="true" :id="`has_v1_${type_Matrice.val}_${data.id}`" @change="setInValidation(slotProps.data)"/>
                              <label class="ml-2" :for="`has_v1_${type_Matrice.val}_${data.id}`"> Modulo Voltura presente</label>
                            </div>
                            <div class="flex align-items-center m-2" v-if="slotProps.data.pratica == 'SUBENTRO' && slotProps.data.is_contratto_manuale">
                              <Checkbox v-model="data.has_s1" :binary="true" :id="`has_s1_${type_Matrice.val}_${data.id}`" @change="setInValidation(slotProps.data)"/>
                              <label class="ml-2" :for="`has_s1_${type_Matrice.val}_${data.id}`"> Modulo Subentro presente</label>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column header="Check Insoluto" style="width: 20%">
                        <template #body="{data}">
                          <div class="card flex align-items-start">
                            <div class="flex align-items-center m-2">
                              <Checkbox v-model="data.insoluto" :binary="true" :id="`insoluto_${type_Matrice.val}_${data.id}`" @change="setInValidation(slotProps.data)"/>
                              <label class="ml-2" :for="`insoluto_${type_Matrice.val}_${data.id}`">Insoluto KO</label>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column header="Decorrenza Voltura" v-if="slotProps.data.pratica == 'VOLTURA' && type_Matrice.val == 'ee'" style="width: 20%">
                        <template #body="{data}">
                          <div class="card flex">
                            <div class="flex flex-column">
                              <div v-for="vt4 in array_vt4" :key="vt4.value" class="flex align-items-center m-1">
                                <RadioButton v-model="data.type_vt4" :id="`vt4_${vt4.value}_${data.id}`" name="dynamic" :value="vt4.value"/>
                                <label :for="`vt4_${vt4.value}_${data.id}`" class="ml-2">{{ vt4.name }}</label>
                              </div>
                              <Calendar v-if="data.type_vt4 == 3" v-model="data.data_decorrenza" dateFormat="dd/mm/yy" :manualInput="false" :minDate="data.minData"/>
                            </div>
                          </div>
                        </template>
                      </Column>
                    </DataTable>
                    <div v-else class="flex flex-wrap">
                      <div v-for="pp in slotProps.data.forniture[type_Matrice.val]" :key="pp.id" class="col-12 flex justify-content-around align-items-center">
                        <h6 class="m-0 p-0">{{ clienti[pp.cliente_id].denominazione }}</h6>
                        <h6 class="m-0 p-0">{{ pp[type_Matrice.val == 'ee' ? 'pod' : 'pdr'] }}</h6>
                        <div :class="[`${pp.has_ft ? 'color-green' : 'color-red'}`]">Fattura {{ pp.has_ft ? '' : 'non' }} presente</div>
                        <div v-if="slotProps.data.pratica" :class="[`${pp[`has_${slotProps.data.pratica[0].toLowerCase()}1`] ? 'color-green' : 'color-red'}`]">Modulo di {{slotProps.data.pratica.toLowerCase()}} {{ pp[`has_${slotProps.data.pratica[0].toLowerCase()}1`] ? '' : 'non' }} presente</div>
                        <div :class="[`${!pp.insoluto ? 'color-green' : 'color-red'}`]">Insoluto {{ !pp.insoluto ? 'non' : '' }} presente</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-content-center align-items-center flex-column col-2" v-if="slotProps.data.step_validazione == 2">
                <div>
                  <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`indietro`" @click="goToPrevStep(slotProps.data)"/>
                </div>
                <div style="height: 1rem"></div>
                <div>
                  <Button icon="pi pi-angle-down" class="p-button-rounded" v-tooltip="`Avanti`" @click="goToNextStep('forniture', slotProps.data)"/>
                </div>
              </div>
            </div>
            <div v-else class="flex justify-content-center align-items-center" style="height: 80px">
              Non validabile
            </div>
          </Fieldset>

          <Fieldset legend="Note" v-if="slotProps.data.note">
            <p class="m-0">
              {{slotProps.data.note}}
            </p>
          </Fieldset>
          <div class="w-full flex flex-column mt-3">
            <div v-if="slotProps.data.note_ko">
              <li v-for="(nota) in slotProps.data.note_ko" :key="nota" class="flex justify-content-between nota">
                <div class="col-1">
                  {{ moment(nota.created_at).format('DD-MM-YYYY') }}: 
                </div>
                <strong>{{ nota.nota }}</strong>
              </li>
            </div>
          </div>

          <div class="flex flex-wrap align-items-center justify-content-between">
            <div class="col-12 flex justify-content-between" v-if="getStatusValidazione(slotProps.data) && slotProps.data.last_step == 0 && slotProps.data.step_validazione == 3">
              <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`Ritorna allo step precedente`"  @click="goToPrevStep(slotProps.data)"/>
              
              <Button v-if="filter != 3" :disabled="!slotProps.data.newNote" label="Ko Documentale" icon="pi pi-times" class="p-button-rounded p-button-danger m-2" @click="validaCaricamento(slotProps.data, 3, false, 'docs')" />
            </div>

            <div class="col-12 flex justify-content-between" v-if="getStatusValidazione(slotProps.data) && slotProps.data.last_step == 1 && slotProps.data.step_validazione == 3" >
              <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`Ritorna allo step precedente`"  @click="goToPrevStep(slotProps.data)"/>

              <Button v-if="isUniqueClient(slotProps.data) && filter != 9" :disabled="!slotProps.data.newNote" label="Blocco credito" icon="pi pi-times" class="p-button-rounded p-button-warning m-2" @click="validaCaricamento(slotProps.data, 9)" />
              <Button v-if="!isUniqueClient(slotProps.data) && filter != 9" :disabled="!slotProps.data.newNote" label="Separa caricamenti" icon="pi pi-times" class="p-button-rounded m-2" @click="validaCaricamento(slotProps.data, 9, false, 'credit')" />
            </div>

            <div class="col-12 flex justify-content-between" v-if="getStatusValidazione(slotProps.data) && slotProps.data.last_step == 2 && slotProps.data.step_validazione == 3">
              <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`Ritorna allo step precedente`"  @click="goToPrevStep(slotProps.data)"/>
            
              <Button v-if="isUniquePP(slotProps.data)" :disabled="!slotProps.data.newNote" label="Ko Documentale" icon="pi pi-times" class="p-button-rounded p-button-danger m-2" @click="validaCaricamento(slotProps.data, 3)" />
              <Button v-if="!isUniquePP(slotProps.data)" :disabled="!slotProps.data.newNote" label="Separa caricamenti" icon="pi pi-times" class="p-button-rounded m-2" @click="validaCaricamento(slotProps.data, 3, false, 'pp')" />
            </div>
            
            <div class="col-12 flex justify-content-between" v-if="!getStatusValidazione(slotProps.data) && slotProps.data.step_validazione == 3" >
              <Button icon="pi pi-angle-up" class="p-button-rounded" v-tooltip="`Ritorna allo step precedente`"  @click="goToPrevStep(slotProps.data)"/>
            
              <Button label="Valida" icon="pi pi-check" class="p-button-rounded p-button-success m-2" @click="validaCaricamento(slotProps.data, 2)" />
            </div>              
            
            <div class="col-12" v-if="getStatusValidazione(slotProps.data)">
              <Textarea v-model="slotProps.data.newNote" class="w-full h-5rem" @update:modelValue="delete validationErrors.newNote" placeholder="Inserisci note"/>
              <small v-show="validationErrors.newNote" class="p-error">Campo necessario.</small>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
  <div v-else>
    <div class="card text-center">
        <p>
          Nessun caricamento per lo stato di caricamento selezionato
        </p>
      <!-- <div v-if="loaded">
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton width="10rem" class="mb-2"></Skeleton>
        <Skeleton width="5rem" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
      <p v-else>Nessun caricamento presente</p> -->
    </div>
  </div>
  <Dialog v-model:visible="displayDialog" :modal="true" style="width: 50%;">
    <h4>{{ title_riepilogo }}</h4>
    <div class="flex justify-content-center">
        <Textarea v-model="selectedItem.newNote" autoResize rows="5" class="w-full" placeholder="Inserisci note"/>
    </div>
    <div class="flex justify-content-end">
      <div class="col-12 xl:col-4 flex justify-content-between">
        <Button :disabled="!selectedItem.newNote" label="Conferma" class="p-button-success p-button-rounded p-button-raised" icon="pi pi-check" @click="validaCaricamento(selectedItem, new_status, true)"/>
        <Button label="Annulla" class="p-button-danger p-button-rounded p-button-raised" icon="pi pi-times" @click="closeDialog()"/>
      </div>
    </div>
  </Dialog>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import { FilterMatchMode } from 'primevue/api';
import moment from 'moment'
import {ref, onMounted, getCurrentInstance, computed} from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Loader from "@/components/Loader.vue"
import { useToast } from 'primevue/usetoast';
import roles from '@/config/roles'
import { useRoute, useRouter } from 'vue-router'
import Textarea from 'primevue/textarea';
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    Loader,
  },  
  setup (props, {emit}) {
    const confirm = useConfirm();
    const dates_firma = ref();
    const authors = ref();
    const ids = ref();
    const stati = ref([
      {
        key:1,
        name:'da validare'
      },
      {
        key:11,
        name:'standby'
      },
      //{
      //  key:2,
      //  name:'validato'
      //},
      {
        key:3,
        name:'ko'
      },
      {
        key:8,
        name:'annullato'
      },
      {
        key:9,
        name:'bloccato'
      }
    ]);
    const selectedDate = ref([
      // moment().startOf("month").toDate(),
      // new Date(),
    ]);
    const maxDate = ref(new Date())
    const filter = ref(1)

    const stati_iniziali = ref({})
    const nuovo_stato = ref({})
    const note_caricamenti = ref({})
    const changed = ref({})
    const route = useRoute()
    const router = useRouter()

    const parametroGet = route.query.matrice_id
    const filters = ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        id: { value: null, matchMode: FilterMatchMode.IN},
        agente: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const toast = useToast();
    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const loading = ref(false)
    const currentUser = ref(store.state.auth.user)
    const items = ref();
    const selectedItem = ref();
    const new_status = ref();
    const title_riepilogo = ref()
    const displayDialog = ref(false);
    const contatori = ref([]);
    const newMatriceDialog = ref(null)
    const typesMatrice = ref([
      {name: 'Elettrico', val: 'ee', device: 'pod'},
      {name: 'Gas', val: 'gas', device: 'pdr'}
    ])
    const typeMatrice = ref()
    const matriceUploadKey = ref(0);
    const typesClient = ref([])
    const selectedTypeClient = ref(null)
    const offerte = ref([])
    const selectedOfferte = ref([])
    const isIvaAgevolata = ref(false)
    const loaded = ref(true)
    const fileToUpload = ref([])
    const allowedType = {
      'xlsx' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    const validationErrors = ref({})
    const submitted = ref(false)
    const showModalMobile = ref(false)
    const showModal = ref(false)
    const showModalPratiche = ref(false)
    const districts = ref([])
    const cities = ref([])
    const toponimi = ref([])
    const allowedTypeDoc = ['pdf','jpeg','jpg','png']
    const files = ref([])
    const matrice_id = ref('')
    const pratiche_s1_ee = ref([])
    const pratiche_s1_gas = ref([])
    const pratiche_v1_ee = ref([])
    const pratiche_v1_gas = ref([])
    const id_matrice_pratiche = ref('')
    const is_contratto_manuale = ref()
    const sent_pratica = ref(false)
    const isDisableShowPratiche = ref(false);
    const now = new Date()
    const op = ref();
    const loadingFilename = ref(false)
    const filesView = ref();
    const expandedRows = ref([]);
    const credit = ref({});
    const lights = ref([
      {check:'RED', color:'danger'},
      {check:'YELLOW', color:'warning'},
      {check:'GREEN', color:'success'}
    ])
    const clienti = ref({})
    const checks = ref([
        {id: 0, step: 'documenti', validazioni: [
          {prop: 'has_documento_identita', value: true}
        ]},
        {id: 1, step: 'credito', validazioni: [
          {prop: 'credit', value: false}
        ]},
        {id: 2, step: 'forniture', validazioni: [
          {prop: 'has_ft', value: true}, {prop: 'insoluto', value: false}
        ]}
    ])

    const getActions = (data) => {
      return [
        {label: 'Ko Documentale', icon: 'pi pi-times', class: 'p-button-custom-warning', new_status: 3, command: (button) => showDialog(data, button.item.new_status)},
        {label: 'Annullamento', icon: 'pi pi-trash', class: 'p-button-custom-danger', new_status: 8, command: (button) => showDialog(data, button.item.new_status)},
        {label: 'StandBy', icon: 'pi pi-comment', class: 'p-button-custom-orange', new_status: 11, command: (button) => showDialog(data, button.item.new_status)}
      ].filter(btn => btn.new_status != filter.value)
    }

    const getTitlesDialog = (id_caricamento) => {
      return [
        {id: 11, title: `Vuoi mettere in StandBy il caricamento #${id_caricamento}?`},
        {id: 8, title: `Vuoi annullare il caricamento #${id_caricamento}?`},
        {id: 3, title: `Vuoi mettere in Ko documentale il caricamento #${id_caricamento}?`}
      ].find(title => title.id == new_status.value).title
    }

    const showDialog = (data, status) => {
      selectedItem.value = data
      new_status.value = status
      title_riepilogo.value = getTitlesDialog(data.id)
      displayDialog.value = true
    }

    const closeDialog = () => {
      selectedItem.value = null
      new_status.value = null
      displayDialog.value = false
      title_riepilogo.value = null      
    }

    const rappresentante = ref({
      cod_fiscale:'',
      name: '',
      phone: '',
      mobile: null,
      email: '',
      pec: '',
      toponimo: null,
      address:'',
      civico: '',
      cap: '',
      selectedDistrict:null,
      selectedCity: null,
      cityId: 0,
      id_existent: '',
      contatto_id:''
    })

    const array_vt4 = ref([
      {
        value:1,
        name:'VT4 a 2 giorni'
      },
      {
        value:2,
        name:'VT4 ordinaria'
      },
      {
        value:3,
        name:'VT4 programmata'
      }
    ])
    const basePath = ref()

    onMounted(() => { 
      basePath.value = service.getBaseAdminUrl()

      loading.value = true
      loaded.value = false
      const promises = [
        getData(),
        getContatori()
      ]
      Promise.all(promises).then(()=>{
        loading.value = false
        loaded.value = true
      })
    })

    const goTo = async (evt, data) => {   
      let type = data.type_agente
      window.open(`${basePath.value}/andamento-agenti/${type}/${data.agente_id}`, '_blank')
    }

    const setInValidation = (data) => {
      let id = items.value.findIndex(item => item.id == data.id)
      items.value[id].in_validation = inValidation(data)
    }
    const onRowExpand = async (event) => {
      let {data} = event
      let element = expandedRows.value.findIndex(row => row.id == data.id)
      
      if (element == -1) {
        expandedRows.value.push(data)
        let cliente_id = data.cliente_id.split(',')
        cliente_id.forEach(id => {
          getExistentCreditCheck(data, id)
        })
      }else{
        expandedRows.value.splice(element, 1)
      }
      expandedRows.value = [...expandedRows.value]
    };
    const onRowCollapse = (event) => {
        // toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };

    const toggle = async (event, id) => {
        loadingFilename.value = true
        op.value.toggle(event);
        await getFileCaricamento(id)
        .finally(()=>{
          loadingFilename.value = false
        })
    }

    const hideOverlay = () => {
      filesView.value = null
    } 

    const getFileCaricamento = async (id) => {
      
      await service.getFileCaricamento(id)
      .then((res) => {
        filesView.value = res.items
      })
    }

    const goToPratiche = () => {
      router.push({path: '/precheck-iv'})
    }

    const getSeverity = (status) => {
      switch (status) {
        case 'da validare':
          return 'warning';

        case 'validato':
          return 'success';
      
        case 'ko':
          return 'danger';

        case 'bloccato':
        case 'annullato':
          return 'secondary';

        case 'standby':
          return 'orange'

      }
    }
    const getLight = (response_color) => {
      let index = lights.value.findIndex(light => light.check == response_color)
      return lights.value[index].color
    }
    const onChangeDate = (evt) => {
      if (selectedDate.value[1]) {
        self.refs.calendarRef.overlayVisible = false;
        getData()
      }
    }
    const onChangeFilter = (filtro) => {
      filter.value = filtro
      getData()
    }

    const getData = async () => {
      loading.value = true       
      // filter = Number(filter)
      let fd = new FormData();
      let range = []
      selectedDate.value.forEach((data) => {
        let giorno = data.getDate() 
        giorno = giorno.toString().padStart(2,'0')
        let mese = data.getMonth()+1
        mese = mese.toString().padStart(2,'0')
        let anno = data.getFullYear() 
        data = `${anno}-${mese}-${giorno}`
        range.push(data)
      })
      fd.append('range', range);

      await service.getAllFilteredCaricamenti(filter.value, fd)
      .then((res) => {
        items.value = res.items
        items.value.forEach((row) => {
          let new_status
          switch (row.cod_stato_caricamento) {
            case 1:
              new_status = 'da validare'
              break;
          
            case 2:
              new_status = 'validato'
              break;

            case 3:
              new_status = 'ko'
              break;

            case 11:
              new_status = 'standby'
              break;
          }
          
          Object.keys(row.forniture).forEach(type => {
            row.forniture[type].forEach(device => {
              device.data_decorrenza = new Date(setDataDecorrenzaProgrammata())
              device.minData = new Date(setDataDecorrenzaProgrammata())
              credit.value[device.cliente_id] = false
              let ateco = device.codiceAteco
              let obj_cliente = {
                'denominazione': device.denominazione_cliente,
                'tipologia': device.tipologia_cliente,
                'cf': device.cf_cliente,
                'piva': device.piva_cliente,
                'ateco': ateco
              }
              clienti.value[device.cliente_id] = obj_cliente
            })
          })
          
          row.last_step = 0
          row.step_validazione = 0
          row.steps = {
            documenti: {valida: true, validabile: true},
            credito: {valida: true, validabile: true},
            forniture: {valida: true, validabile: true}
          }
          row.cod_stato_caricamento = new_status
          stati_iniziali.value[row.id] = row.cod_stato_caricamento 
          nuovo_stato.value = {...stati_iniziali.value}  
          row.in_validation = inValidation(row)        
        })
        populateAuthorsArray(res.items)
        populateIdsArray(res.items)
      })
      .finally(()=> {
        changed.value = {}
        stati.value.forEach((stato)=>{
          if (stato.key == filter.value) {
            stato.selected = true
          }else{
            stato.selected = false
          }
        })
        loading.value = false       
      })
    }
    const setDataDecorrenzaProgrammata = () => {
      let now = moment()

      do {
        now.add(1, 'day')
      } while ([0,6].includes(now.day()));

      return now.add(3, 'days')
    }
    const setCreditCheckBox = (id_cliente) => {
      items.value.forEach(item => {
        Object.keys(item.forniture).forEach(type_pp => {
          item.forniture[type_pp].forEach(pp => {
            if (pp.cliente_id == id_cliente) {
              pp.credit = credit.value[id_cliente]
            }
          })
        })
        setInValidation(item)
      })
    }

    const getExistentCreditCheck = async (data, id_cliente) => {
      loading.value = true   
      let fd = new FormData()
      fd.append('id_cliente', id_cliente)

      initDataCreditCheck(data, id_cliente)
      await service.existCallCreditCheck(fd)
      .then((res) => { 
        if (res.items.created_at) {
          setCreditCheckExistent(res, data, id_cliente)
        }
      })
      .finally(()=> {
        loading.value = false       
      }) 
    }
    const callCreditCheck = async (data, id_cliente, new_request = false) => {
      loading.value = true   
      let fd = new FormData()
      fd.append('id_cliente', id_cliente)
      fd.append('new_request', JSON.stringify(new_request))

      initDataCreditCheck(data, id_cliente)
      await service.callCreditCheck(fd)
      .then((res) => { 
        setCreditCheckExistent(res, data, id_cliente)
      })
      .finally(()=> {
        loading.value = false       
      })        
    }

    const setCreditCheckExistent = (res, data, id_cliente) => {
      if (data.cod_type_client == 3) {
        items.value.forEach(item => {
          Object.keys(item.forniture).forEach(type_pp => {
            item.forniture[type_pp].forEach(pp => {
              if (pp.cliente_id == id_cliente) {
                pp.credit_check_response = res.items  
                pp.has_credit_check = res.items.code_status == 200
                pp.loaded_credit_check = true
              }
            })
          })
        })
      } else {
        data.credit_check_response = res.items
        data.has_credit_check = res.items.code_status == 200
        data.loaded_credit_check = true
      }
      clienti.value[id_cliente].ateco = res.items.ateco
    }

    const initDataCreditCheck = (data, id_cliente) => {
      if (data.cod_type_client == 3) {
        items.value.forEach(item => {
          Object.keys(item.forniture).forEach(type_pp => {
            item.forniture[type_pp].forEach(pp => {
              if (pp.cliente_id == id_cliente) {
                pp.loaded_credit_check = false
              }
            })
          })
        })
      } else {
        data.loaded_credit_check = false
      }
    }

    // const disabledDates = computed(()=>{
    //   let array_date = []
    //   let now = moment().add(2, 'days')
    //   for (let index = 1; index < 5; index++) {
    //     now.add(1, 'day')
    //     if ([0,6].includes(now.day())) {
    //       array_date.push(new Date(now));
    //     }
        
    //   }

    //   console.log(array_date);
    //   return array_date
    // })

    const getContatori = async () => {
      await service.getContatoriCtrValidation()
      .then((res) => {
        contatori.value = res.items
      })
    }
    const inValidation = (data) => {
      let inValidation = []
      let props_data = ['has_documento_identita']
      if (data.is_contratto_manuale) {
        props_data.push('has_contratto')
      }

      props_data.forEach(prop => {
        inValidation.push(data[prop])
      });

      let props_supply = [
        'has_ft',
        'insoluto',
        'credit'
      ]
      if (data.pratica) {
        props_supply.push(`has_${data.pratica[0].toLowerCase()}1`)
      }
      // if (data.cod_type_client in [1,4]) {
      //   props_supply.push('credit')
      // }
      let type_dato_true = [
        'insoluto','credit'
      ]

      Object.keys(data.forniture).forEach(type => {
        data.forniture[type].forEach(row => {
          props_supply.forEach(prop => {
            inValidation.push(type_dato_true.includes(prop) ? !row[prop] : row[prop])
          })
        })
      })

      return !inValidation.includes(false)
    } 


    const populateDatesFirmaArray = (items) => {
      let dates = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.data_firma) {
            //moment(data.data_firma).format('DD-MM-YYYY HH:mm')
            dates.push(moment(el.data_firma).format('YYYY-MM-DD'))
          }
        });
        dates_firma.value = [...new Set(dates)];
      }
    }

    const populateAuthorsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.agente) {
            list.push(el.agente)
          }
        });
        authors.value = [...new Set(list)];
        authors.value.sort()
      }
    }
    const populateIdsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.id) {
            list.push(el.id)
          }
        });
        ids.value = [...new Set(list)];
      }
    }

    const showPdf = (data) => {
      loading.value = true
      service.showPdfMatrice(data.id)
      .finally(()=> {
        loading.value = false
      })
    }


    const validateFormNewMatrice = () => {

      if (selectedTypeClient.value) {
        delete validationErrors.value['type_client'];
      } else {
        validationErrors.value['type_client'] = true;
      }

      if (typeMatrice.value) {
        delete validationErrors.value['type_matrice'];
      } else {
        validationErrors.value['type_matrice'] = true;
      }

      if (fileToUpload.value.length == 0) {
          validationErrors.value['files'] = true;
          delete validationErrors.value['type'];
      } else {
        delete validationErrors.value['files'];
      }

      if (fileToUpload.value.length > 0) {
        let valid = [...fileToUpload.value].every(file => [Object.values(allowedType).join(', ')].includes(file.type), false);
        if (valid) 
          delete validationErrors.value['type'];
        else
          validationErrors.value['type'] = true;
      }

      return !Object.keys(validationErrors.value).length;
    }

    const saveNotes = (id, nota) => {
      loading.value = true
      let fd = new FormData();
      fd.append('matrice_id', JSON.stringify(id));
      fd.append('nota', JSON.stringify(nota));
      service.saveNota(fd)
      .then((res) => {
        if (res.item) {
          toast.add({severity:'success', summary: 'Successo', detail:`Nota salvata correttamente`});
        } else {
          toast.add({severity:'warn', summary:'Attenzione', detail: `Errore nel salvataggio della nota`});
        }
      }).finally(() => {
        getData(true)
        loading.value = false
      })
    }

    const viewDocumenti = (id) => {
      loading.value = true
      service.viewDocumenti(id)
      .then((res)=> {
        // const path = URL.createObjectURL(res.items);
        // window.open(path);
        // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
        let pdfContents = res.item;
        if (pdfContents == 'Non ci sono documenti') {
          toast.add({severity:'warn', summary:'Errore documenti', detail: pdfContents});
        } else {
          pdfContents.forEach(pdfContent => {
            const decodedData = Uint8Array.from(atob(pdfContent), c => c.charCodeAt(0));
            const blob = new Blob([decodedData], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            // Apri l'URL di dati in una nuova finestra del browser
            window.open(pdfUrl);
          });          
        }
      })
      .finally(()=>{
        loading.value = false
      })
    }

    const viewDocumento = async (id, name) => {
      loading.value = true
      if (name.includes('.zip')) {
        await service.downloadPdf(id, name)
        .finally(()=> {
          loading.value = false
        })
      } else {
        await service.viewDocumento(id, name)
        .then((res)=> {
          // Supponiamo che tu abbia ricevuto i contenuti dei file PDF come dati JSON dal backend
          let pdfContents = res.item;
          if (pdfContents == 'Non ci sono documenti') {
            toast.add({severity:'warn', summary:'Errore documenti', detail: pdfContents});
          } else {
            pdfContents.forEach(pdf => {
              const pdfContent = pdf[0]
              const pdfType = pdf[1]
  
              const decodedData = Uint8Array.from(atob(pdfContent), c => c.charCodeAt(0));
              const blob = new Blob([decodedData], { type: pdfType });
              const pdfUrl = URL.createObjectURL(blob);
              // Apri l'URL di dati in una nuova finestra del browser
  
              window.open(pdfUrl);
            });          
          }
        })
        .finally(()=>{
          loading.value = false
        })        
      }
    }

    const onChangeStatus = (model, id) => {
      nuovo_stato.value[id] = stati.value.find(stato => stato.name == model.name).name
      if (model.name == stati_iniziali.value[id]) {
        changed.value[id] = false
      }else{
        changed.value[id] = true
      }
      delete validationErrors.value[id]
    }
    const setData = (el) => {
      let giorno = el.getDate() 
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-${giorno}`
      return el
    } 
    const setDateDecorrenza = (data) => {
      Object.keys(data.forniture).forEach(type=>{
        data.forniture[type].forEach(device => {
          if (device.type_vt4 == 3) {
            device.decorrenza = setData(device.data_decorrenza)
          }
        })
      })
    }
    const validaCaricamento = (data, new_status, all_caricamento = false, type = '') => {
      if (new_status != 2 && (data.newNote == '' || data.newNote == null)) {
        validationErrors.value.newNote = true
        return
      }
      loading.value = true
      let fd = new FormData();
      setDateDecorrenza(data)
      fd.append('matrice', JSON.stringify(data));
      fd.append('new_status', new_status);
      fd.append('all_caricamento', all_caricamento);
      fd.append('type', type);
      service.validaCaricamento(fd)
      .then((res) => {
        let detail = 'Errore del cambio stato'
        let severity = 'warn' 
        if (!res.items.error) {
          severity = 'success'
          switch (res.items.result) {
            case 2:
              detail = 'Caricamento validato correttamente'
              break;
            case 3:
              detail = 'Caricamento in ko documenti'
              break;
            case 8:
              detail = 'Contratto annullato'
              break;
            case 9:
              detail = 'Caricamento bloccato'
              break;
            case 11:
              detail = 'Caricamento in StandBy'
              break;
            default:
              detail = 'Caricamento da validare'
              break;
          }
          if (all_caricamento) {
            closeDialog()
          }
        } 
        toast.add({severity:severity,  detail:detail, life: 10000});
      }).finally(() => {
        Promise.all([getData()]).finally( () => loading.value = false)
      })
    }
    const saveChangedStatus = (id) => {
      let new_status = stati.value.find(stato => stato.name == nuovo_stato.value[id]).key
      if (new_status == 3 && (note_caricamenti.value[id] == null || note_caricamenti.value[id] == '')) {
        validationErrors.value[id] = true
      }else{
        delete validationErrors.value[id]
      }
      if (validationErrors.value[id]) {
        toast.add({severity:'warn', summary:'Errore', detail: `Nota mancante per il caricamento ${id}`, life:3000});
      }else{
        loading.value = true
        let fd = new FormData();
        fd.append('matrice_id', JSON.stringify(id));
        fd.append('nuovo_stato', JSON.stringify(new_status));
        if(note_caricamenti.value[id]){
          fd.append('nota', JSON.stringify(note_caricamenti.value[id]));
        }
        service.saveChangedStatus(fd)
        .then((res) => {
          let detail = 'Errore del cambio stato'
          let severity = 'warn' 
          if (res.items) {
            severity = 'success'
            switch (new_status) {
              case 2:
                detail = 'Caricamento validato correttamente'
                break;
              case 3:
                detail = 'Caricamento in ko documenti'
                break;
              default:
                detail = 'Caricamento da validare'
                break;
            }
          } 
          toast.add({severity:severity,  detail:detail});
        }).finally(() => {
          delete note_caricamenti.value[id]
          getData()
          loading.value = false
        })

      }  
    }
    const deleteNota = (id) => {
      service.deleteNota(id)
      .then((res) => {
        if (res.items) {
          toast.add({severity:'success', summary: 'Successo', detail:`Nota eliminata correttamente`});
        } else {
          toast.add({severity:'warn', summary:'Attenzione', detail: `Errore nell'eliminazione della nota`});
        }
      })
      .finally(() => {
        getData()
      })
    }
    const onInputKO = (id) => {
      delete validationErrors.value[id]
    }

    const getSeverityEta = (eta) => {
      if (eta > 0 && eta <= 65) {
        return 'success'
      } else if(eta > 65 && eta < 73){
        return 'warn'
      }else{
        return 'error'
      }
    }

    const getSeverityAteco = (ateco) => {
      return ateco ? 'success' : 'error'
    }

    const goToNextStep = (type_step, data) => {
      let currentStep = checks.value.find(check => check.step == type_step).id
      validateStep(currentStep, data)
      // data.last_step = currentStep == 0 ? currentStep : (!getStatusValidazione(data) ? currentStep+1 : currentStep)
      data.last_step = currentStep
      data.step_validazione = !getStatusValidazione(data) ? currentStep+1 : 3
    }

    const validateStep = (currentStep, data) => {
      let check_validation = JSON.parse(JSON.stringify(checks.value))
      
      if (data.is_contratto_manuale) {
        check_validation[0].validazioni.push({prop: 'has_contratto', value: true})
      }
      if (data.pratica) {
        check_validation[0].validazioni.push({prop: `has_titolo_giust`, value: true})
        check_validation[2].validazioni.push({prop: `has_${data.pratica[0].toLowerCase()}1`, value: true})
      }

      let current_validazione = check_validation[currentStep]
      
      let validate = []
      current_validazione.validazioni.forEach(validazione => {
        if (currentStep == 0) {
          let check = data[validazione.prop] == validazione.value
          validate.push(check)
        } else if(currentStep == 1){
          let clienti = data.cliente_id.split(',')
          clienti.forEach(cliente => {
            let check = credit.value[cliente] == validazione.value
            validate.push(check)
          })
        }else{
          Object.keys(data.forniture).forEach(type_fornitura => {
            data.forniture[type_fornitura].forEach(fornitura => {                                  
              let check = fornitura[validazione.prop] == validazione.value
              validate.push(check)
            })
          })
        } 
      })


      let steps = [
        {id: 0, prop: 'documenti'},
        {id: 1, prop: 'credito'},
        {id: 2, prop: 'forniture'}
      ]
      // let same_current_step = validate.filter(validi => !validi).length > 0
      // let valid = !validate.filter(validi => !validi).length > 0
      // let other_step = steps.filter(el => el.id >= current_validazione.id)
      // console.log(steps);
      // other_step.forEach(step => {
      //   if (step.id == current_validazione.id && same_current_step) {
      //     data.steps[step.prop].valida = valid
      //   }
      //   data.steps[step.prop].validabile = valid
      // })
      let has_false_check = validate.filter(validi => !validi).length > 0
      
      steps.forEach(step => {
        let valida = true
        let validabile = true
        if (has_false_check) {
          if(step.id == current_validazione.id){
            valida = false
            // validabile = true
          }else if(step.id > current_validazione.id){
            // valida = true
            validabile = false
          }
        }

        data.steps[step.prop].valida = valida
        data.steps[step.prop].validabile = validabile
      })
      // if (validate.filter(validi => !validi).length > 0) {
      //   same_current_step = true
      //   valid = false

      // }else{
      //   same_current_step = false
      //   valid = true
      // }
      
    }

    const getStatusValidazione = (data) => {   
      let hasInvalidStep = false;

      for (const step in data.steps) {
        if (!data.steps[step].valida) {
          hasInvalidStep = true;
          break;
        }
      }     
      return hasInvalidStep
      // return Object.values(data.steps).some(st => !st)
    }

    const goToPrevStep = (data) => {
      data.step_validazione = data.last_step
      data.last_step = data.last_step == 0 ? data.last_step : data.last_step-1

      validateStep(data.step_validazione, data)
      
    }

    const isUniquePP = (data) => {
      let count = 0
      let count_error = 0
      
      Object.keys(data.forniture).forEach(type_fornitura => {
        data.forniture[type_fornitura].forEach(pp => {
          let result_check = []
          let checks = [
            {prop: 'has_ft', value: true},
            {prop: 'insoluto', value: false}
          ]
          if (data.pratica) {
            checks.push({prop: `has_${data.pratica[0].toLowerCase()}1`, value: true})
          }
          checks.forEach(check => {
            result_check.push(check.value == pp[check.prop])
          })
          count++
          if (result_check.some(res => !res)) {
            count_error++
          }
        })
      })
      return count > 1 && count != count_error ? false : true
    }

    const isUniqueClient = (data) => {
      let clienti = data.cliente_id.split(',')
      let count = clienti.length
      let count_error = 0
      clienti.forEach(cliente => {
        if (credit.value[cliente]) {
          count_error++
        }
      })
      
      return count > 1 && count != count_error ? false : true
    }

    const formatCurrency = (value) => {
      return value.toLocaleString('it-IT')
    };

    return {
      moment,
      filters,
      dates_firma,
      authors,
      ids,
      items,
      selectedItem,
      displayDialog,
      loading,
      loaded,
      newMatriceDialog,
      typeMatrice,
      typesMatrice,
      matriceUploadKey,
      typesClient,
      selectedTypeClient,
      offerte,
      selectedOfferte,
      isIvaAgevolata,
      showPdf,
      fileToUpload,
      allowedType,
      // onCheckRowMultisito,
      validationErrors,
      submitted,
      roles,
      currentUser,
      contatori,
      showModal,
      showModalMobile,
      showModalPratiche,
      rappresentante,
      districts,
      cities,
      toponimi,
      allowedTypeDoc,
      files,
      goToPratiche,
      pratiche_s1_ee,
      pratiche_s1_gas,
      pratiche_v1_ee,
      pratiche_v1_gas,
      sent_pratica,
      isDisableShowPratiche,
      is_contratto_manuale,
      stati,
      getSeverity,
      viewDocumenti,
      changed,
      saveNotes,
      onChangeStatus,
      saveChangedStatus,
      stati_iniziali,
      nuovo_stato,
      note_caricamenti,
      filter,
      getData,
      deleteNota,
      onInputKO,
      selectedDate,
      onChangeDate,
      onChangeFilter,
      maxDate,
      toggle,
      op,
      filesView,
      getFileCaricamento,
      hideOverlay,
      loadingFilename,
      viewDocumento,
      expandedRows,
      onRowExpand,
      onRowCollapse,
      validaCaricamento,
      setInValidation,
      array_vt4,
      // disabledDates,
      callCreditCheck,
      credit,
      setCreditCheckBox,
      lights,
      getLight,
      getSeverityEta,
      getSeverityAteco,
      goTo,
      goToNextStep,
      goToPrevStep,
      clienti,
      getStatusValidazione,
      getActions,
      showDialog,
      closeDialog,
      title_riepilogo,
      isUniquePP,
      isUniqueClient,
      new_status,
      formatCurrency,
    }
  },
}
</script>

<style>
.p-tieredmenu .p-button-custom-orange{
  background-color: rgb(251, 149, 46) !important;
  border-color: rgb(251, 149, 46) !important;
  color:#ffffff !important;
}
.p-tieredmenu .p-button-custom-danger{
  background-color: rgb(204, 42, 42) !important;
  border-color: rgb(204, 42, 42) !important;
  color:#ffffff !important;
}

.p-tieredmenu .p-button-custom-warning{
  color: #212529;
  background: #FBC02D;
  border: 1px solid #FBC02D;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #000000;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #000000;
    margin-right: 0.5rem;
}

@media (max-width: 400px){
  .layout-main > .card{
    padding-left: unset;
  }
}

</style>
<style scoped>
.p-button-orange{
  background-color: rgb(251, 149, 46) !important;
  border-color: rgb(251, 149, 46) !important;
  color:#ffffff !important;
}
.layout-main > .card{
  min-width: 390px !important;
  padding-right: 2rem;
}
@media (max-width: 400px){
  .p-column-header-content .p-column-title{
    font-size: 1vw;
  }

  .layout-main > .card{
    padding-left: unset;
  }
}
.btn-filter{
  /* font-size: 0.7rem !important; */
  aspect-ratio: 4/1.1;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.btn-filter:hover {
  opacity: 1;
}
.btn-filter .p-button-label{
  font-size: 1em;
  line-height: 1em;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
}

.btn-filter.selected {
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-file .button-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.form-file .allowed-format {
  font-size: 12px;
  color: rgb(186, 186, 186);
  margin-top: 2px;
}

.form-file .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgb(217, 217, 217) !important; 
  cursor: pointer;
  color: rgb(168, 168, 168);
  padding: 10px 2px;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  box-shadow: none;
}

.form-file .button-wrapper span.label.active {
  background: #2196F3;
  color: white;
}

.form-file #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.btn-downloads-matrici {
  position:fixed;
  bottom:60px; 
  right:40px;
  z-index:2;
}
.nota{
  border-bottom: 1px solid #6c757d3d;
  margin-bottom: 10px;
}

.traffic-light-active{
  opacity:1;
}
.traffic-light-inactive{
  opacity:0.15;
}
.color-green{
  color:rgb(77, 204, 42);
  font-size: 20px;
}

.color-red{
  color:rgb(204, 42, 42);
  font-size: 20px;
}
</style>

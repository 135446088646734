import axios from 'axios'
import services from '@/config/services'
import authHeader from '../auth/auth-header';
import Cookies from 'js-cookie';
import FileManager from '@/services/ask-file-manager/index.js'

export default class PraticheService{
  constructor(eventBus){
    this.eventBus = eventBus
    this.baseUrl = `${services.ask_pratiche.url}`
    this.baseUrlToken = `${services.ask_pratiche_token.url}`
    this.baseUrlApi = `${services.ask_pratiche_api.url}`
    this.baseUrlFlussi = `${services.ask_flussi_api.url}`
    this.baseUrlScraper = `${services.ask_pratiche_scraper.url}`
    this.baseAdminUrl = `${services.admin.url}`
    this.filemanager = new FileManager()
    axios.interceptors.request.use(
      (req) => {
        req.headers["Authorization"] = authHeader()
        req.headers["Params"] = Cookies.get('google_access_token') || Cookies.get('ask_access_token')
        // this.eventBus.emit('data-loading');
        return req
      }
    )
  }

  async getCtrsComputed(){
    let items = {}
    const url = `${this.baseUrl}/get-ctrs-computed`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')          
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }

  async getToponimi(){
    let items = {}
    const url = `${this.baseUrl}/get-toponimi`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }

  async getAllTipoClient(){
    let items = {}
    const url = `${this.baseUrl}/get-tipo-cliente`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }
  async getAllStati(){
    let items = {}
    const url = `${this.baseUrl}/get-stati-caricamento`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async checkCodeExist(value){
    let items = {}
    const url = `${this.baseUrl}/get-cod-fiscale/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async checkExistentClient(value, check, type_client){
    let items = {}
    const url = `${this.baseUrl}/get-${check}-for-type-${type_client}/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async calculateCF(name, surname, birth, gender, city){
    let cf = {}
    const url = `${this.baseUrl}/calculate-cod-fiscale/${name}/${surname}/${birth}/${gender}/${city}`  

    try {
        let res = await axios.get(url)
        cf = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            cf,
        }
    }

  }


  async checkIvaExist(value){
    let items = {}
    const url = `${this.baseUrl}/get-partita-iva/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }

  
  async getInfoCreditSafe(value){
    let item = {}
    const url = `${this.baseUrl}/get-info-creditsafe/${value}`

    try {
        let res = await axios.get(url)
        item = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            item,
        }
    }

  }


  async getRegions(){
    let items = {}
    const url = `${this.baseUrl}/get-regions`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }


  async getDistricts(value = null){
    let items = {}
    const url = `${this.baseUrl}/get-districts/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }


  async getCities(value = null){
    let items = {}
    const url = `${this.baseUrl}/get-cities/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getSDI(){
    let items = {}
    const url = `${this.baseUrl}/get-sdi`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  
  async getAgents(){
    let items = {}
    const url = `${this.baseUrl}/get-agents`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getHeadings(){
    let items = {}
    const url = `${this.baseUrl}/get-fields`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
 
  async getCityId(value){
    let item = {}
    const url = `${this.baseUrl}/get-cities-id/${value}`

    try {
        let res = await axios.get(url)
        item = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            item,
        }
    }
  }

  async getRappresentanti(){
    let items = {}
    const url = `${this.baseUrl}/get-rappresentanti`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }

  async getRappresentanteFromCF(cf){
    let items = {}
    const url = `${this.baseUrl}/get-rappresentante-from-cf/${cf}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }



  async saveClient(fd){
    let success = false;
    let item = [];
    const url = `${this.baseUrl}/save-client`
    const settings = { headers: { 'content-type': 'multipart/form-data' } }

    try {
        let res = await axios.post(url, fd, settings)
        this.eventBus.emit('data-load-success', {message: res.data.message})
        success = true
        item = res.data.item
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        success = false
    } finally {
        this.eventBus.emit('data-loaded') 
        // eslint-disable-next-line no-unsafe-finally
        return {
            success: success,
            item: item
        }     
    }

  }
  async getRapp(value){
    let items = {}
    const url = `${this.baseUrl}/get-rapp/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }

  }
  async checkCodeRappExist(value){
    let items = {}
    const url = `${this.baseUrl}/get-cod-fiscale-rapp/${value}`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async checkRappExist(rappresentante, is_complete){
    let items = {}
    const url = `${this.baseUrl}/check-cod-fiscale-rapp`
    try {
        let res = await axios.post(url, {
            rapp: rappresentante,
            is_complete: is_complete
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async checkSupply(value){
    let items = {}
    const url = `${this.baseUrl}/check-supply`
    try {
        let res = await axios.post(url, {
            data: value
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async checkSupplyActive(value){
    let items = {}
    const url = `${this.baseUrl}/check-supply-active`
    try {
        let res = await axios.post(url, {
            data: value
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getCitiesRapp(){
    let items = []
    const url = `${this.baseUrl}/get-cities-rapp`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async saveRappresentante(value) {
    const url = `${this.baseUrl}/save-rappresentante`
    
    try {
        let res = await axios.post(url, {
          data: value
        })
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err}
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }

  async getClients(){
    let items = []
    const url = `${this.baseUrl}/get-clients`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getContacts(id, type){
    let items = []
    const url = `${this.baseUrl}/get-contacts/${id}/${type}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getIbanClient(idClient){
    let items = []
    const url = `${this.baseUrl}/get-iban-client`

    try {
        let res = await axios.post(url, {'idClient': idClient})
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getClientsId(idClient){
    let item = {}
    const url = `${this.baseUrl}/get-clients-id/${idClient}`

    try {
        let res = await axios.get(url)
        item = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            item,
        }
    }
  }
  
  async getClientsKey(value) {
    let items = []
    const url = `${this.baseUrl}/get-clients-search`
    
    try {
        let res = await axios.post(url, {
          data: value
        })
        items = res
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
    return {
        items,
    }
  }


  async getTipiCtr(){
    let items = []
    const url = `${this.baseUrl}/get-tipi-ctr`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getCtrExsist(value){
    let items = []
    const url = `${this.baseUrl}/get-ctr-exist/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  
  async stopCtr(value){
    //let response = ''
    const url = `${this.baseUrl}/stop-ctr`

    try {
        let res = await axios.post(url, {
            data: value
        })
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }

  
  async getResellers(){
    let items = []
    const url = `${this.baseUrl}/get-resellers`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getOfferte(){
    let items = []
    const url = `${this.baseUrl}/get-offerte`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getOfferteAgent(id){
    let items = []
    const url = `${this.baseUrl}/get-offerte-for-agent-${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getAgenti(){
    let items = []
    const url = `${this.baseUrl}/get-agenti`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getRegimi(){
    let items = []
    const url = `${this.baseUrl}/get-regimi`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getIvas(){
    let items = []
    const url = `${this.baseUrl}/get-ivas`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getFatturazioni(){
    let items = []
    const url = `${this.baseUrl}/get-fatturazioni`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async getSpedizioni(){
    let items = []
    const url = `${this.baseUrl}/get-spedizioni`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getScadenze(){
    let items = []
    const url = `${this.baseUrl}/get-scadenze`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getPagamenti(){
    let items = []
    const url = `${this.baseUrl}/get-pagamenti`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getPeriodi(){
    let items = []
    const url = `${this.baseUrl}/get-periodi`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  
  async getAcquisizioni(){
    let items = []
    const url = `${this.baseUrl}/get-acquisizioni`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getRids(){
    let items = []
    const url = `${this.baseUrl}/get-rids`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

 


  async saveContract(fd) {
    let success = false;
    let idContratto = [];
    let idCliente = [];
    let tipo = null;
    const url = `${this.baseUrl}/save-contract`
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    
    try {
        let res = await axios.post(url, fd, settings)
        
        this.eventBus.emit('data-load-success', {message: res.data.message})
        success = true
        idContratto = res.data.idContratto
        idCliente = res.data.idCliente
        tipo = res.data.tipo
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        success = false
    } finally {
        this.eventBus.emit('data-loaded')
         // eslint-disable-next-line no-unsafe-finally
         return {
          success: success,
          idContratto: idContratto,
          idCliente: idCliente,
          tipo: tipo
      }      
    }
  }

  
  async getContract(id){
    let items = []
    const url = `${this.baseUrl}/get-contract/${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  
  async getTipiOfferta(){
    let items = []
    const url = `${this.baseUrl}/get-tipi-offerta`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  
  async getTariffazioni(){
    let items = []
    const url = `${this.baseUrl}/get-tariffazioni-offerta`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  
  async nameOffertaExist(value){
    let items = []
    const url = `${this.baseUrl}/validate-name-offerta/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async saveOfferta(value) {

    const url = `${this.baseUrl}/save-offerta`

    try {
        let res = await axios.post(url, {
          data: value
        })

        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err}
    } finally {
        this.eventBus.emit('data-loaded')  
    
    }
  }

  async checkIvaFornitoreExist(value){
    let items = {}
    const url = `${this.baseUrl}/get-partita-iva-fornitore/${value}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async saveFornitore(value){

    const url = `${this.baseUrl}/save-fornitore`
    
    try {
        let res = await axios.post(url, {
          data: value
        })
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err}
    } finally {
        this.eventBus.emit('data-loaded')      
    }

  }

  async saveAgente(value){

    const url = `${this.baseUrl}/save-agente`
    
    try {
        let res = await axios.post(url, {
          data: value
        })
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err}
    } finally {
        this.eventBus.emit('data-loaded')      
    }

  }

  
  async checkPodExist(value){
    let item = {}
    const url = `${this.baseUrl}/check-pod-exist/${value}`

    try {
        let res = await axios.get(url)
        item = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            item,
        }
    }
  }

  
  async precheckPod(pod, cliente){
    let valid = []
    let cod_pratica = Date.now()
    let date = (new Date()).toISOString().split('T')[0];
    let string_data = ''
    if (cliente.piva == '') {
        string_data = `cf=${cliente.codfis}&cf_straniero=false` 
    } else {
        string_data = `piva=${cliente.piva}` 
    }
    
    const url = `${this.baseUrlApi}/management/acquirente-unico/agf/pods/${pod}/pre-check?cod_pratica=${cod_pratica}&${string_data}&data_switch=${date}`
    try {
        let res = await axios.get(url)
        valid = res.data
        //this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')
         // eslint-disable-next-line no-unsafe-finally
         return {
            valid,
        }      
    }

  }

  async getStatusProviderSii(){
 
    const url = `${this.baseUrlApi}/management/acquirente-unico/status`

    try {
      let res = await axios.get(url)
      if (res.data == 200) {
        return true
      }
      return false
    } catch (err) {
      return false
    } 
  }


  async getCtrsActive(clientId, typeCtr){
    let items = []
    const url = `${this.baseUrl}/get-ctrs-active/${clientId}/${typeCtr}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  
  async getDistributori(){
    let items = []
    const url = `${this.baseUrl}/get-distributori`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async getMisuratori(){
    let items = []
    const url = `${this.baseUrl}/get-misuratori`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getUsiEnergy(){
    let items = []
    const url = `${this.baseUrl}/get-usi-energy`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getResidenti(){
    let items = []
    const url = `${this.baseUrl}/get-residenti`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getTensioni(){
    let items = []
    const url = `${this.baseUrl}/get-tensioni`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getStati(){
    let items = []
    const url = `${this.baseUrl}/get-stati`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getStime(){
    let items = []
    const url = `${this.baseUrl}/get-stime`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async saveFornituraEnergy(fd){
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    const url = `${this.baseUrl}/save-fornitura-energy`
    
    try {
        let res = await axios.post(url, fd, settings)
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err} 
    } finally {
        this.eventBus.emit('data-loaded')      
    }

  }


  async checkPdrExist(value){
    let item = {}
    const url = `${this.baseUrl}/check-pdr-exist/${value}`

    try {
        let res = await axios.get(url)
        item = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            item,
        }
    }
  }


  async getUsiGas(){
    let items = []
    const url = `${this.baseUrl}/get-usi-gas`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async getClassiGas(){
    let items = []
    const url = `${this.baseUrl}/get-classi-gas`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getCategorieGas(){
    let items = []
    const url = `${this.baseUrl}/get-categorie-gas`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async saveFornituraGas(fd){
    const settings = { headers: { 'content-type': 'multipart/form-data' } }   
    const url = `${this.baseUrl}/save-fornitura-gas`
    
    try {
        let res = await axios.post(url, fd, settings)
        this.eventBus.emit('data-load-success', {message: res.data})
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        return {errore: err} 
    } finally {
        this.eventBus.emit('data-loaded')      
    }

  }

  async saveALLWizardAgenti(fd){
    let success = false;
    let item = [];
    const url = `${this.baseUrl}/save-wizard-agenti`
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    try {
        const res = await axios.post(url, fd, settings)

        this.eventBus.emit('data-load-success', {message: res.data.message})
        success = true
        item = res.data.item
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
        success = false
    } finally {
        this.eventBus.emit('data-loaded') 
        // eslint-disable-next-line no-unsafe-finally
        return {
            success: success,
            item: item
        }     
    }

  }
  async downloadTemplateMultisito(headings, id){
    const url = `${this.baseUrl}/multisito/download/template`
    try {
      let res = 
      await axios({
          url,
          method: 'POST',
          data: {headings, id},
          responseType: 'blob',
        })
      //this.eventBus.emit('data-load-success', {message: res.data})

      this.filemanager.download(res)

    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')   
    }
  }

  async saveMultisitoData(fd){
    let item = [];
    const url = `${this.baseUrl}/multisito/data/newsave`
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    try {
      const res = await axios.post(url, fd, settings)

      //this.eventBus.emit('data-load-success', {message: res.data.message})
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async saveMultisitoPratiche(fd){
    let item = [];
    const url = `${this.baseUrl}/multisito/data/pratiche`
    try {
      const res = await axios.post(url, fd)
      //this.eventBus.emit('data-load-success', {message: res.data.message})
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async saveNota(fd){
    let item = [];
    const url = `${this.baseUrl}/multisito/data/save-nota`
    try {
      const res = await axios.post(url, fd)
      //this.eventBus.emit('data-load-success', {message: res.data.message})
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }
  async viewDocumenti(id){
    let item = [];
    const url = `${this.baseUrl}/view-all-documents-${id}`
    try {
      const res = await axios.get(url)
      //this.eventBus.emit('data-load-success', {message: res.data.message})
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async viewDocumento(id, name){
    let item = [];
    const url = `${this.baseUrl}/view-document`
    try {
      const res = await axios.post(
        url, 
        {
            'matrice_id':id,
            'file':name
        }
      )
      //this.eventBus.emit('data-load-success', {message: res.data.message})
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }


  async updateRapp(fd){
    let item = [];
    const url = `${this.baseUrl}/multisito/dataRapp/save`
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    try {
      const res = await axios.post(url, fd, settings)
      item = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      // eslint-disable-next-line no-unsafe-finally
      return {
        item
      }     
    }
  }

  async getPrecheck(data){
    let item = [];
    const url = `${this.baseUrl}/multisito/precheck`
    if (await this.getStatusProviderSii()) {
        try {
            const res = await axios.post(url, {data:data})
            item = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded') 
            // eslint-disable-next-line no-unsafe-finally
            return {
            item
            }     
        }
    }else{
        return {
            item
        }
    }
  }
  async getRemi(city = null){
    let items = []
    let uri = 'get-total-remi'
    if (city) {
        uri = `get-remi-${city}`
    }
    const url = `${this.baseUrl}/${uri}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async getAllCaricamentiMultisito(fd){
    let items = []
    const url = `${this.baseUrl}/multisito/caricamenti`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getAllFilteredCaricamenti(filter, fd){
    let items = []
    const url = `${this.baseUrl}/multisito/all-caricamenti-${filter}`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getSlides(id){
    let items = []

    const url = `${this.baseUrl}/multisito/get-slide-${id}`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   async getDetailsSupplies(type, fd){
    let items = []

    const url = `${this.baseUrl}/forniture-${type}`
    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }

   async saveChangesMultisito(fd){
    let items = []

    const url = `${this.baseUrl}/multisito/data/richiesta-modifiche`
    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }

  async getReportCaricamenti(fd){
    let items = []
    let report = []
    const url = `${this.baseUrl}/multisito/report-mese`

    try {
        let res = await axios.post(url, fd)
        items = res.data.settimane
        report = res.data.report_agenzie
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,report
        }
    }
  }

  async getReportSettimana(fd){
    let items = []
    const url = `${this.baseUrl}/multisito/report-settimana`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items
        }
    }
  }

  async sendDeleteContratto(fd){
    let items = []
    const url = `${this.baseUrl}/multisito/annulla-contratto`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items
        }
    }
  }

  async getReportCaricamentiAgente(fd){
    let items = []
    let report = []
    const url = `${this.baseUrl}/multisito/report-mese-agente`

    try {
        let res = await axios.post(url, fd)
        items = res.data.items
        report = res.data.report_agenzie
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,report
        }
    }
  }
   async deleteNota(id){
    let items = []

    const url = `${this.baseUrl}/validazione/elimina-nota-${id}`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   async getIsActiveTutorial(id){
    let items = []

    const url = `${this.baseUrl}/get-data-agente-${id}`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   
   async getUpdate(){
    let items = []

    const url = `${this.baseUrl}/get-update-wizard`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   async setInactiveTutorial(id){
    const url = `${this.baseUrl}/set-inactive-tutorial-${id}`
    try {
        await axios.get(url)
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
    }
   }
   async saveChangedStatus(fd){
    let items = []

    const url = `${this.baseUrl}/validazione/cambia-stato`
    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   async validaCaricamento(fd){
    let items = []

    const url = `${this.baseUrl}/validazione/valida-caricamento`
    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }
   async sendValidation(fd){
    let items = []

    const url = `${this.baseUrl}/multisito/data/valida-richiesta-modifiche`
    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }

  async getContatori(fd){
    let items = []
    const url = `${this.baseUrl}/multisito/dati-caricamenti`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getFileCaricamento(id){
    let items = []
    const url = `${this.baseUrl}/multisito/get-file-caricamento-${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getDetailsCaricamento(fd){
    let items = []
    const url = `${this.baseUrl}/multisito/get-details-caricamento`

    try {
        let res = await axios.post(url, fd)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getContatoriCtrValidation(){
    let items = []
    const url = `${this.baseUrl}/multisito/dati-validazione`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async deleteUpdate(data){
    let res
    const url = `${this.baseUrl}/multisito/delete-update`

    try {
        res = await axios.post(url, {
            matrice_id : data.id
        })
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded', {message: 'Caricamento eliminato'})    
    }
    return res

  }



  async uploadNewMatrice(type_matrice, files, typeClient, isIvaAgevolata, offerte){
    let items = []
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    const url = `${this.baseUrl}/multisito/martice/new`
    let fd = new FormData();
    fd.append('type_matrice', JSON.stringify(type_matrice));
    fd.append('typeClient', JSON.stringify(typeClient));
    fd.append('isIvaAgevolata', JSON.stringify(isIvaAgevolata));
    fd.append('offerte', JSON.stringify(offerte));
    for (let i = 0 ; i < files.length ; i++) {
      fd.append('files[]', files[i]); 
    }
    
    try {
      let res = await axios.post(url, fd, settings)
      items = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      // eslint-disable-next-line no-unsafe-finally
      return {
        items
      }    
    }
  }

  async uploadMatriceCaricata(id, files){
    let items = []
    const settings = { headers: { 'content-type': 'multipart/form-data' } }
    const url = `${this.baseUrl}/multisito/martice/upload`
    let fd = new FormData();
    fd.append('id_matrice', JSON.stringify(id));
    for (let i = 0 ; i < files.length ; i++) {
      fd.append('files[]', files[i]); 
    }
    
    try {
      let res = await axios.post(url, fd, settings)
      items = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      // eslint-disable-next-line no-unsafe-finally
      return {
        items
      }    
    }
  }

  async downloadMatriceCaricata(id){
    
    const url = `${this.baseUrl}/multisito/martice/download/${id}`

    try {
      let res = 
      await axios({
          url,
          method: 'GET',
          // data: {headings},
          responseType: 'blob',
        })

      this.filemanager.download(res)

    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }


  async downloadsMatriciCaricate(items){
    
    const url = `${this.baseUrl}/multisito/martici/download`

    try {
      let res = 
      await axios({
          url,
          method: 'POST',
          data: {items},
          responseType: 'blob',
        })

      this.filemanager.download(res)

    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }
  async getAgentAgency(id){
    let items = []
    const url = `${this.baseUrl}/get-sub-agents-${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }


  async showPdfMatrice(id){
    
    // const url = `${this.baseUrlFlussi}/contratto/compila`
    const url = `${this.baseUrlFlussi}/contratto/compila-new`

    try {
        let res = await axios({
            url,
            method: 'POST',
            data: {matrice_id: id, showPDF: true},
            responseType: 'blob',
        })
        this.filemanager.download(res)
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }
  async downloadPdf(id, filename){
    const url = `${this.baseUrl}/multisito/download-file-caricamento`
    try {
      let res = await axios({url,
          method: 'POST',
          data: {
            matrice_id: id,
            file: filename,
            // dir: directory
          },
          responseType: 'blob',
        })
      //this.eventBus.emit('data-load-success', {message: res.data})

      this.filemanager.download(res)

    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')   
    }
  }

  async downloadZip(id){
    const url = `${this.baseUrl}/multisito/download-zip-caricamento-${id}`
    try {
        let res = await axios({url,
            method: 'GET',
            responseType: 'blob'
        })
      //this.eventBus.emit('data-load-success', {message: res.data})

      this.filemanager.download(res)

    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')   
    }
  }

  async showPdfPratiche(id){
    const url = `${this.baseUrlFlussi}/pratiche/compila-pratiche`

    try {
      let res = await axios({
          url,
          method: 'POST',
          data: {matrice_id: id, showPDF: true},
          responseType: 'blob',
        })

      this.filemanager.download(res)

    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
    }
  }


  async downloadIvFile(){
    const url = `${this.baseUrlScraper}/scarica-informativa-voltura`
    
    let res = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
    })
    this.filemanager.download(res)
    return this.filemanager.getFileName(res)
  }


  async downloadFileName(file){
    const url = `${this.baseUrlScraper}/scarica-file-${file}`
    let res = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
    })
    this.filemanager.download(res)
  }


  async downloadResponseIvFile(id){
    const url = `${this.baseUrlScraper}/scarica-esito-iv`
    
    let res = await axios({
        url,
        method: 'POST',
        data: {id_risposta: id},
        responseType: 'blob',
    })
    this.filemanager.download(res)
  }
  async downloadResponsePrecheckFile(id, supply){
    const url = `${this.baseUrlScraper}/scarica-esito-pk`
    let res = await axios({
        url,
        method: 'POST',
        data: {id_risposta: id, type: supply},
        responseType: 'blob',
    })
    this.filemanager.download(res)
  }




  async downloadPrecheckFile(type){
    const url = `${this.baseUrlScraper}/scarica-precheck-${type}`
    let res = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
    })
    this.filemanager.download(res)
    return this.filemanager.getFileName(res);
  }
  async downloadSwitchable(){
    const url = `${this.baseUrl}/scarica-switchable`
    let res = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
    })
    this.filemanager.download(res)
  }
  async getPratiche(){
    let items = []
    const url = `${this.baseUrlScraper}/file-pratiche`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async saveIdPratica(filename, id){
    let url = `${this.baseUrlScraper}/associa-file-pratica`
    let fd = new FormData();
    fd.append('filename', JSON.stringify(filename));
    fd.append('id', JSON.stringify(id));
    let res = await axios({
        url,
        method: 'POST',
        data: fd,
    })
    return res.data
  }
//   async uploadIvFile(file, id){
//     let url = `${this.baseUrlScraper}/invia-informativa-voltura`
//     let fd = new FormData();
//     fd.append('id', JSON.stringify(id));
//     for (let i = 0 ; i < file.length ; i++) {
//       fd.append('files[]', file[i]); 
//     }
//     let res = await axios({
//         url,
//         method: 'POST',
//         data: fd,
//     })
//     return res.data
//   }

//   async uploadPrecheckFile(file, id, supply){
//     let url = `${this.baseUrlScraper}/invia-precheck`
//     let fd = new FormData();
//     fd.append('supply', JSON.stringify(supply));
//     fd.append('id', JSON.stringify(id));
//     for (let i = 0 ; i < file.length ; i++) {
//       fd.append('files[]', file[i]); 
//     }
//     let res = await axios({
//         url,
//         method: 'POST',
//         data: fd,
//     })
//     return res.data
//   }

  async uploadPraticaFile(file, id, type, supply = null){
    let url = `${this.baseUrlScraper}/invia-risposta-pratica`
    let fd = new FormData();
    if (supply) {
        fd.append('supply', JSON.stringify(supply));
    }
    fd.append('id', JSON.stringify(id));
    fd.append('type', JSON.stringify(type.toUpperCase()));
    for (let i = 0 ; i < file.length ; i++) {
      fd.append('files[]', file[i]); 
    }
    let res = await axios({
        url,
        method: 'POST',
        data: fd,
    })
    return res.data
  }
//   async uploadNewFile(files, id){
//     let url = `${this.baseUrl}/multisito/upload-files`
//     let fd = new FormData();
//     fd.append('matrice_id', JSON.stringify(id));
//     for (let i = 0 ; i < files.length ; i++) {
//       fd.append('files[]', files[i]); 
//     }
//     let res = await axios({
//         url,
//         method: 'POST',
//         data: fd,
//     })
//     return res.data
//   }

async uploadNewFile(fd){
    let url = `${this.baseUrl}/multisito/upload-files`
    let res = await axios({
        url,
        method: 'POST',
        data: fd,
    })
    return res.data
  }

  async signedContract(fd){
    let items = []

    let url = `${this.baseUrl}/multisito/upload-signed-contract`
    try {
        let res = await axios({
            url,
            method: 'POST',
            data: fd,
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items
        }
    }
  }

  async sendSignData(fd){
    let items = []

    let url = `${this.baseUrlFlussi}/eSign/send-sign-data`
    try {
        let res = await axios({
            url,
            method: 'POST',
            data: fd,
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items
        }
    }
  }

  async sendRequestSignData(fd){
    let items = []

    let url = `${this.baseUrl}/multisito/send-request-sign-data`
    try {
        let res = await axios({
            url,
            method: 'POST',
            data: fd,
        })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items
        }
    }
  }

  async getDettaglioOfferta(id){
    let items = {}

    const url = `${this.baseUrl}/offerte/${id}`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      // eslint-disable-next-line no-unsafe-finally
      return {
        items
      }
    }
  }




  async signPdfMatrice(id){
    let items = []
    const url = `${this.baseUrlFlussi}/eSign/invia-richiesta`

    try {
        let res = await axios.post(url, { matrice_id: id })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async signPdfPratiche(id){
    let items = []
    const url = `${this.baseUrlFlussi}/eSign/invia-richiesta-pratiche`

    try {
        let res = await axios.post(url, { matrice_id: id })
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getStatiPrecheck(){
    let items = []
    const url = `${this.baseUrl}/get-stati-precheck`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getStatiContratto(){
    let items = []
    const url = `${this.baseUrl}/get-stati-contratto`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getStatiInternalPratica(){
    let items = []
    const url = `${this.baseUrl}/get-stati-internal-pratica`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getSignLink(id){
    let items = []
    const url = `${this.baseUrlFlussi}/eSign/get-signature-link-${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }

  async getSignData(id){
    let items = []
    const url = `${this.baseUrlFlussi}/eSign/get-signature-data-${id}`

    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
  }
  async getCountPodForIV(){
    let items = []
    const url = `${this.baseUrlScraper}/controlla-informativa-voltura`
        let res = await axios.get(url)
        items = res.data
        return {
            items
        }
  }
  async getCountSupplyForPrecheck(type){
    let items = []
    const url = `${this.baseUrlScraper}/controlla-precheck-${type}`
        let res = await axios.get(url)
        items = res.data
        return {
            items
        }
  }

  async countUseContact(cell){
    let items 
    const url = `${this.baseUrl}/conta-inserimenti-possibili`

    try {
        let res = await axios.post(url, { cell: cell})
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return items
    }
  }
  async getAllRequiredChanges(){
    let items = []

    const url = `${this.baseUrl}/multisito/data/get-richieste-modifiche`
    try {
        let res = await axios.get(url)
        items = res.data
    } catch (err) {
        this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
        this.eventBus.emit('data-loaded')      
        // eslint-disable-next-line no-unsafe-finally
        return {
            items,
        }
    }
   }

    async getNbModifiche(){
        let items = []
        const url = `${this.baseUrl}/multisito/data/get-number-modifiche`

        try {
            let res = await axios.get(url)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items,
            }
        }
    }

    async downloadExport(ids){
        let url = `${this.baseUrl}/download-export`
        try {
            let res = await axios({
                url,
                method: 'POST',
                data: {ids},
                responseType: 'blob',
            })            
            this.filemanager.download(res)
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')   
        }
    }

    async downloadExportEsiti(ids){
        let url = `${this.baseUrl}/download-export-esiti`
        try {
            let res = await axios({
                url,
                method: 'POST',
                data: {ids},
                responseType: 'blob',
            })            
            this.filemanager.download(res)
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')   
        }
    }

    async callCreditCheck(fd){
        let items = []
        const url = `${this.baseUrl}/get-credit-check`

        try {
            let res = await axios.post(url, fd)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }
        }
    }
    async existCallCreditCheck(fd){
        let items = []
        const url = `${this.baseUrl}/exist-credit-check`

        try {
            let res = await axios.post(url, fd)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }
        }
    }
    async searchSupply(fd){
        let items = []
        const url = `${this.baseUrl}/multisito/get-stato-fornitura`

        try {
            let res = await axios.post(url, fd)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }
        }
    }

    async setSupply(fd){
        let items = []
        const url = `${this.baseUrl}/multisito/set-stato-fornitura`

        try {
            let res = await axios.post(url, fd)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }
        }
    }

    getBaseAdminUrl(){
        return this.baseAdminUrl
    }

    async verifyTokenLinkLibero(token){
        let items = null
        const url = `${this.baseUrlToken}/verifica-token-caricamento`

        try {
            let res = await axios.post(url, {token})
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }
        }
    }

    async getConvenzioni(){
        let items = []
        const url = `${this.baseUrl}/convenzioni/get-convenzioni`

        try {
            let res = await axios.get(url)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items,
            }
        }
    }

    async getAgentiOfferte(){
        let items = []
        const url = `${this.baseUrl}/convenzioni/agenti-offerte`

        try {
            let res = await axios.get(url)
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded')      
            // eslint-disable-next-line no-unsafe-finally
            return {
                items,
            }
        }
    }

    async sendNewConvenzione(fd){
        let item = [];
        const url = `${this.baseUrl}/convenzioni/save-new-convenzione`
        const settings = { headers: { 'content-type': 'multipart/form-data' } }
        try {
            const res = await axios.post(url, fd, settings)
            
            //this.eventBus.emit('data-load-success', {message: res.data.message})
            item = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded') 
            // eslint-disable-next-line no-unsafe-finally
            return {
                item
            }     
        }
    }

    async checkCodicePromozionale(codice){
        let items = [];
        const url = `${this.baseUrl}/check-codice-promozionale`
        try {
            const res = await axios.post(url, {'codice_promozionale': codice})
            
            //this.eventBus.emit('data-load-success', {message: res.data.message})
            items = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded') 
            // eslint-disable-next-line no-unsafe-finally
            return {
                items
            }     
        }
    }

    async CanViewInputPromo(){
        let item;
        const url = `${this.baseUrl}/can-view-input-promo`
        try {
            const res = await axios.get(url)
            
            //this.eventBus.emit('data-load-success', {message: res.data.message})
            item = res.data
        } catch (err) {
            this.eventBus.emit('data-load-error', {message: err, url})
        } finally {
            this.eventBus.emit('data-loaded') 
            // eslint-disable-next-line no-unsafe-finally
            return {
                item
            }     
        }
    }
}

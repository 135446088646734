<template>
  <div class="stepsdemo-content">
    <Card v-if="is_complete_rapp">
      <template v-slot:title>
        <div class="grid">
          <!-- <div class="col-12 xl:col-1 d-button">
            <Button label="Tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>
          </div>
          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div class="border-1 surface-border border-round text-center py-5 px-3">
                  <div class="mb-1">
                    <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+slotProps.data.size]"/>
                  </div>
                  <div>
                    <p class="mb-1"><strong>{{ slotProps.data.description }}</strong></p>
                  </div>
                </div>
              </template>
            </Carousel>
          </Dialog> -->
          <p class="col-12">Upload Documenti</p>
        </div>
        
      </template>
      <template v-slot:subtitle>
        Documenti necessari:
        <li><strong>Documento di riconoscimento del firmatario</strong></li>
        <li><strong>Ultima fattura per ciascun punto di fornitura</strong></li>
        <li v-if="typeClient == 1"><strong>Opzionale - Visura camerale</strong></li>
        <li v-if="isManualContract && is_pratica"><strong>Moduli {{ pratica }}</strong></li>
      </template>
      
      <template v-slot:content>
        <div style="position: relative;">
          <Button class="btn-offerte btn-tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>

          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false" style="min-width: 300px;">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div :class="['h-full',{'flex align-items-center': windowSize < 700}]">
                  <div v-if="windowSize > 700" class="border-1 surface-border border-round text-center py-5 px-3">
                    <div class="mb-1">
                      <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+(windowSize < 1400 ? slotProps.data.size+2 : slotProps.data.size)]"/>
                    </div>
                  </div>
                  <p class="m-2 text-center"><strong>{{ slotProps.data.description }}</strong></p>
                </div>
              </template>
            </Carousel>
          </Dialog>
        </div>

        <div style="margin-top: 50px;">
          <!-- <div class="m-5 text-center">
            <label><strong>Contratto firmato manualmente</strong></label>
            <div class="m-4 flex justify-content-center">
              <Checkbox v-model="isManualContract" :binary="true" @change="onChange"/>
            </div>
          </div> -->
          <div class="flex flex-wrap justify-content-center">
            <div v-for="(document, index) in requiredDocuments" :key="document.name" class="col-12 sm:col-10 md:col-7 lg:col-3 xl:col-2">
              <div class="flex">
                <div v-if="document.ref == 'gen'" class="col-1"></div>
                <div :class="['flex flex-column align-items-center mb-3 justify-content-center', document.ref == 'gen' ? 'col-10' : 'col-12']">
                  <div class="button-wrapper mb-2">
                    <span class="p-1 label flex align-items-center justify-content-center">Carica {{document.name}}</span>
                    <input class="upload-file" :name="`${document.ref}`" type="file" :ref="document.ref" @change="handleFileNewUpload($event, document.ref)" accept="application/pdf,image/gif,image/jpeg,image/jpg,image/png" :multiple="document.multiple" :disabled="isDisableInput"/>
                  </div>
                  <div>
                    <p class="text-center">
                      File selezionati:
                    </p>
                    <ul>
                      <li v-for="file in document.files" :key="file.name">{{ file.name }}</li>
                    </ul>
                  </div>
                  <div v-show="validationErrors[`${ref}`] && submitted" class="p-error">Campo richiesto.</div>
                  <div v-show="validationErrors[`type_${ref}`] && submitted" class="p-error">Formato non consentito</div>
                </div>
                <Button v-if="document.ref == 'gen'" v-tooltip="`Elimina altri file`" @click="deleteFile(index)" icon="pi pi-trash" class="col-1 p-button-rounded p-button-text p-button-danger"/>
              </div>
            </div>
            <div v-if="isVisibleAddFile">
              <Button v-tooltip="`Aggiungi file`" @click="addFile" icon="pi pi-plus" class="p-button-rounded p-button-text p-button-success" />
            </div>
          </div>
          <div class="text-center">
            <small>Formati consentiti: {{allowedType.join(", ")}}</small>
          </div>
          <div class="mt-3 flex justify-content-center">
            <Textarea class="w-full" placeholder="Inserisci nota" v-model="note" variant="filled" rows="5" cols="30" />
          </div>
          <div v-if="isManualContract" class="mt-3 grid justify-content-center">
            <div class="flex flex-column">
              <Label>Data firma cartaceo:</Label>
              <Calendar v-model="data_firma_cartaceo" :maxDate="maxDate" :manualInput="false" dateFormat="dd/mm/yy" @update:modelValue="onChangeDataFirma"/>
              <div v-show="validationErrors.data_firma_cartaceo && submitted" class="p-error">Campo richiesto.</div>
            </div>
          </div>
        </div>
        
      </template>
      <template v-slot:footer>
        <div class="grid justify-content-between">
          <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" class="p-btn-footer"/>
          <Button label="Conferma caricamento" :disabled="isDisableGeneraContratto" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer"/>
        </div>
      </template>
    </Card>
    <Card v-else>
      <template v-slot:title>
        <div class="grid">
          <div class="col-12 xl:col-1 d-button">
            <Button label="Tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>
          </div>
          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div class="border-1 surface-border border-round text-center py-5 px-3">
                  <div class="mb-1">
                    <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+slotProps.data.size]"/>
                  </div>
                  <div>
                    <p class="mb-1"><strong>{{ slotProps.data.description }}</strong></p>
                  </div>
                </div>
              </template>
            </Carousel>
          </Dialog>
          <p class="col-12 xl:col-10">Genera contratto temporaneo</p>
        </div>
        
        
      </template>
      <template v-slot:content>
        <div class="flex align-items-center flex-column p-small p-3">
          <p><strong>In questa modalità viene generato un contratto temporaneo da mostrare al rappresentante, poiché i dati del rappresentante sono incompleti.</strong></p>
          <p>Per procedere alla firma del contratto, è necessario aggiornare i dati del rappresentante e caricare il documento d'identità del firmatario e le fatture dei punti di fornitura da attivare.</p>
        </div>
      </template>
      
      <template v-slot:footer>
        <div class="grid justify-content-between">
          <Button label="Indietro" @click="prevPage()" icon="pi pi-angle-left" iconPos="left" class="p-btn-footer"/>
          <Button label="Genera contratto" :disabled="isDisableGeneraContratto" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer"/>
        </div>
      </template>
      
    </Card>
  </div>
  
  <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :dismissableMask="true" :modal="true" @update:visible="handleDialogClose">
    <!-- :autoZIndex="false" -->
    <div class="confirmation-content">  
      <div v-if="isManualContract">
        <p>Il contratto è in fase di elaborazione. Verifica dalla sezione "Caricamenti" lo stato di avanzamento del caricamento <strong>#{{ matrice_id }}</strong>.</p>
      </div>      
      <div v-else>
        <p v-if="is_complete_rapp">Visualizza e richiedi la firma del contratto</p>    
        <p v-else>Visualizza il contratto.<br> Per richiedere la firma, aggiorna i dati del rappresentante al caricamento <strong>#{{ matrice_id }}</strong>.</p>    
      </div>  
    </div>
    <template #footer>
      <div class="col-12">
        <router-link v-if="!is_complete_rapp" :to="{path:`/caricamenti-multisito`, query: { matrice_id: matrice_id }}">
          <Button 
          label="Vai a caricamenti" 
          class="p-button-primary btn-multisito m-2"
          type="button" icon="pi pi-arrow-right" iconPos="left"
          />
        </router-link>
        <router-link v-else :to="{path:`/caricamenti-multisito`}">
          <Button 
          label="Vai a caricamenti" 
          class="p-button-primary btn-multisito m-2"
          type="button" icon="pi pi-arrow-right" iconPos="left"
          />
        </router-link>
        
      </div>
      <div class="col-12" v-if="!isManualContract">
        <Button @click="showPdf(matrice_id)" :disabled="isDisableShowPdf" label="Scarica Contratto" type="button" icon="pi pi-eye" iconPos="left" class="p-button-danger btn-multisito m-2" />
        
        <Button v-if="is_complete_rapp" @click="signPdf(matrice_id)" label="Richiedi Firma OTP" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-primary btn-multisito m-2" :disabled="isDisableSignPdf"/>
        
        <!-- <div class="button-wrapper mb-2">
          <span class="label">Carica File</span>
          <input :disabled="!is_complete_rapp" id="uploadContratto" name="files" type="file" ref="file" @change="handleFileUpload( $event )" accept="application/pdf,image/gif, image/jpeg, image/jpg,image/png" multiple/>
        </div> -->
      </div>
      
    </template>
  </Dialog>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated, computed} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'   
import Card from 'primevue/card';
import Button from 'primevue/button';
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'
import { useToast } from "primevue/usetoast";


export default {
  components: {
      Card,
      Button,
  },
  props: {
      formData: Object
  },

  emits: ["next-page","prev-page"],

  setup (props, {emit}) {
    const toast = useToast();
    const files = ref([])
    const cartaceo = ref([])
    const di = ref([])
    const ft = ref([])
    const vs = ref([])
    const ctr = ref([])
    const is_pratica = ref(props.formData.pratica == null ? false : true)
    const pratica = ref()

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)
    const stepIndex = 3
    const allowedType = ['pdf','jpeg','jpg','png']
    const validationErrors = ref({})
    const submitted = ref(false)
    const showModal = ref(false)
    const matrice_id = ref('');
    const note = ref('');
    const isDisableShowPdf = ref(false);
    const isDisableSignPdf = ref(false);
    const isDisableInput = ref(false)
    const windowSize = ref(window.innerWidth)

    const typeClient = ref('');
    const is_complete_rapp = ref(true);
    const visible = ref(false)
    const isManualContract = ref()
    const data_firma_cartaceo = ref()
    const maxDate = new Date()
    const isDisableGeneraContratto = computed(()=>{
      let disabled = true
      let has_files = []
      requiredDocuments.value.forEach((doc)=>{
        if (doc.files.length) {
          has_files.push(true)
        }
      })
      disabled = has_files.length == requiredDocuments.value.length ? false : true
      return disabled
    });

    const requiredDocuments = ref([])
    const slides = ref([])
    
    onUpdated(()=>{
      mount()
    })
    onMounted(()=>{
      mount()
    })

    const mount = () => {
      setResponsive()
      getSlides()
      isManualContract.value = props.formData.contratto_manuale
      requiredDocuments.value = [
        {
          name:'Documento di identità',
          ref:'di',
          files:[],
          multiple:false
        },
        {
          name:'Fatture',
          ref:'ft',
          files:[],
          multiple:true
        }
      ]
      typeClient.value = props.formData.typeClient
      
      if (props.formData.pratica && [1,2,4].includes(typeClient.value)) {
        requiredDocuments.value.push({
          name:`Titolo giustificativo`,
          ref:'tg',
          files:[],
          multiple:false
        })
      }

      let indexFt
      switch (props.formData.pratica) {
        case 'v':
          pratica.value = 'voltura'
          break;
        case 's':
          pratica.value = 'subentro'
          indexFt = requiredDocuments.value.findIndex(el => el.ref == 'ft')
          requiredDocuments.value.splice(indexFt,1)
          break;
      }      
      if (props.formData.contratto_manuale) {
        data_firma_cartaceo.value = new Date()
        requiredDocuments.value.push({
          name:'Contratto Cartaceo',
          ref:'ctr',
          files:[],
          multiple:false
        })
        if (props.formData.pratica != null) {
          props.formData.manualRows.forEach(row => {
            requiredDocuments.value.push({
              name:`${pratica.value} ${row.POD_PDR}`,
              ref:row.POD_PDR,
              files:[],
              multiple:false
            })           
          })
        }
      }

      
      emit("activeLoader", true)
      refresh()
      files.value = []
      cartaceo.value = []
      setIsComplete()
      emit('activeLoader', false)
    }

    const setResponsive = () => {
      window.addEventListener('resize', () => {
        windowSize.value = window.innerWidth
      })
    }



    const getSlides = async () => {
      await service.getSlides(stepIndex)
      .then((res) => {
        slides.value = res.items
        slides.value.forEach(slide => {
          slide.name = `/images/Step/step${stepIndex}-${slide.order}.png`
        })
      })
    }

    const refresh = () => {
      window.onbeforeunload = function() {
        emit('complete')
      };
    }
    const openPosition = () => {
      visible.value = true;
    }
    const setIsComplete = () => {
      is_complete_rapp.value = typeClient.value == 2 ? true : props.formData.is_complete
      isDisableGeneraContratto.value = files.value.length == 0 ? true : false
      isDisableGeneraContratto.value = is_complete_rapp.value ?? false
    }

    const onChangeDataFirma = () => {
      delete validationErrors.value.data_firma_cartaceo
    }
    const nextPage = async () => {
      submitted.value = true;
      if (await validateForm()) {
        emit("activeLoader", true)
        let fd = new FormData();
        fd.append('data', JSON.stringify(props.formData.manualRows));
        fd.append('type', JSON.stringify(props.formData.type_matrice));
        fd.append('rappresentante', JSON.stringify(props.formData.rappresentante));
        fd.append('is_complete_rapp', JSON.stringify(is_complete_rapp.value));
        fd.append('type_client', JSON.stringify(props.formData.typeClient));
        fd.append('iva', JSON.stringify(props.formData.isIvaAgevolata));
        fd.append('contatto', JSON.stringify(props.formData.contatto));
        fd.append('cliente', JSON.stringify(props.formData.cliente));
        fd.append('spedizione', JSON.stringify(props.formData.spedizione));
        fd.append('contratto_manuale', JSON.stringify(isManualContract.value));
        fd.append('pratica', JSON.stringify(props.formData.pratica));
        fd.append('agente', props.formData.agente);
        fd.append('prima_data_utile', JSON.stringify(props.formData.prima_data_utile));
        fd.append('data_switch_pod', JSON.stringify(props.formData.data_switch_pod));
        fd.append('data_switch_pdr', JSON.stringify(props.formData.data_switch_pdr));
        fd.append('id_promozione', props.formData.id_promozione);
        fd.append('id_cliente_promozione', props.formData.id_cliente_promozione);

        let data_firma
        if (data_firma_cartaceo.value) {
          let giorno = data_firma_cartaceo.value.getDate() 
          giorno = giorno.toString().padStart(2,'0')
          let mese = data_firma_cartaceo.value.getMonth()+1
          mese = mese.toString().padStart(2,'0')
          let anno = data_firma_cartaceo.value.getFullYear() 
          data_firma = `${anno}-${mese}-${giorno}`  
        }
        fd.append('data_firma', JSON.stringify(data_firma));
        fd.append('note', JSON.stringify(note.value));
        requiredDocuments.value.forEach((doc)=>{
          for (let i = 0 ; i < doc.files.length ; i++) {
            fd.append(`${doc.ref}[]`, doc.files[i]); 
          }
        })

        service.saveMultisitoData(fd)
        .then((res) => {
          if (res.item.success) {
            toast.add({severity:'success', summary: 'Successo', detail:`${res.item.totals} righe importate`});
            showModal.value = true;
            matrice_id.value = res.item.matrice_id
          } else {
            toast.add({severity:'warn', summary:'Attenzione', detail: `${res.item.totals} righe importate`});
          }
        }).finally(() => {
          isDisableInput.value = true
          emit("activeLoader", false)
        })
      }        
    }

    const prevPage = () => {
      emit('prev-page', {pageIndex: stepIndex});
    }

    const handleDialogClose = (newVisibleState) => {
      // Questo metodo verrà chiamato quando la modale viene chiusa.
      if (!newVisibleState) {
        emit('complete')
      }
    }
  
    const handleFileUpload = (evt, idInput) => {
      if (idInput == 'files') {
        files.value = []
        files.value = self.refs.file1.files;
      } else {
        cartaceo.value = []
        cartaceo.value = self.refs.file2.files;
      }
    };
    const handleFileNewUpload = (evt, ref) => {
      let index = requiredDocuments.value.findIndex(el => el.ref == ref)
      requiredDocuments.value[index].files = []
      requiredDocuments.value[index].files = self.refs[`${ref}`].files
    };

    const showPdf = (data) => {
      emit("activeLoader", true)
      service.showPdfMatrice(data)
      .finally(()=> {
        isDisableShowPdf.value = true
        emit("activeLoader", false)
      })
    }

    const signPdf = (data) => {
      emit("activeLoader", true)
      service.signPdfMatrice(data)
      .then((res)=> {
        // if (res.items) {
        //   toast.add({severity:'success', summary:'Successo', detail: 'Richiesta di firma inviata correttamente'});
        //   setInterval(()=>{
        //     location.reload();
        //   },5000)
        // }
        let severity = res.items.success ? 'success' : 'error'
        let summary = res.items.success ? 'Successo': 'Attenzione'
        let detail = res.items.errore ?? 'Richiesta di firma inviata correttamente'
        
        toast.add({severity:severity, summary: summary, detail:detail});
        if (res.items.success) {
          isDisableSignPdf.value = true
          setInterval(()=>{
            location.reload();
          },5000)
        }
      })
      .finally(()=> {
        emit("activeLoader", false)
      })
    }

    const validateForm = () => {
      requiredDocuments.value.forEach((doc)=>{
        if (doc.files.length == 0) {
          validationErrors.value[`${doc.ref}`] = true;
          delete validationErrors.value[`type_${doc.ref}`];
        } else {
          
          let valid = [...doc.files].every(file => allowedType.includes(file.type.replace(/(.*)\//g, '')), false);
          if (valid){
            delete validationErrors.value[`${doc.ref}`];
            delete validationErrors.value[`type_${doc.ref}`];
          }else{
            validationErrors.value[`type_${doc.ref}`] = true;
          }
        }
      })
      if (isManualContract.value) {
        if (data_firma_cartaceo.value) {
          delete validationErrors.value.data_firma_cartaceo;
        }else {
         validationErrors.value.data_firma_cartaceo = true;
        }
      } 
    
      return !Object.keys(validationErrors.value).length;
    }

    const addFile = async () => {
      requiredDocuments.value.push({
        name:'Altri documenti',
        ref:'gen',
        files:[],
        multiple:true
      })            
    }

    const isVisibleAddFile = computed(() => {
      return !requiredDocuments.value.some(doc => doc.ref == 'gen')
    })

    const deleteFile = (index) => {
      requiredDocuments.value.splice(index, 1);
    }

    return {
      files,
      cartaceo,
      di,
      ft,
      vs,
      ctr,
      validationErrors, 
      submitted, 
      service, 
      nextPage, 
      prevPage, 
      handleFileUpload,
      handleFileNewUpload,
      allowedType,
      showModal,
      matrice_id,
      showPdf,
      signPdf,
      handleDialogClose,
      isDisableGeneraContratto,
      typeClient,
      isDisableShowPdf,
      isDisableSignPdf,
      props,
      is_complete_rapp,
      openPosition,
      visible,
      slides,
      isManualContract,
      requiredDocuments,
      is_pratica,
      pratica,
      note,
      isDisableInput,
      maxDate,
      data_firma_cartaceo,
      windowSize,
      onChangeDataFirma,
      addFile,
      deleteFile,
      isVisibleAddFile,
    }
  },
}
</script>
<style>
.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  height: 50px !important;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0);
}

#upload, #uploadCartaceo , #di, #vs, #ft, #ctr, .upload-file{
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px !important;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

li{
  list-style-type: none;
}
</style>
<style scoped>
  .btn-offerte{
    border-radius: 50% !important;
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .btn-offerte:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border-color: #2196F3;
  }

  @media (max-width: 400px) {
    .p-small{
      font-size: 12px !important;
    }
  }
  .p-card{
    min-width: 335px !important;
  }
  .p-dialog-mask{
    z-index: 2;
  }
</style>